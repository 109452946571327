import { BarCanvasProps, BarDatum, BarCanvasCustomLayerProps } from '@nivo/bar'
//@ts-ignore
import domtoimage from 'dom-to-image-more'

import { CleanResponseData, ResponseData } from 'api/types'

import { relationGetDisplayValue } from 'utils/relational'

// data type that is used by nivo bar
export type BarReshapedDataType = Array<{
  [propertyKey: string]: string
}>

// data type that is used by nivo geo
export type GeoReshapedDataType = Array<{
  id: string
  value: number
}>

export type LineReshapedDataType = Array<{
  id: string
  color: string
  data: Array<{
    x: number | string
    y: number | string
  }>
}>

export const UPPER_LIMIT = 50

export type DynamicKeyType<T> = Record<string, T>

export const barChartCommonProperties: Partial<BarCanvasProps<BarDatum>> = {
  pixelRatio: 2,
  indexBy: 'key',
  enableGridY: false,
  padding: 0.1,
  groupMode: 'stacked',
  indexScale: { type: 'band', round: true },
  labelTextColor: 'black',
  labelSkipWidth: 40,
  labelSkipHeight: 12,
}

export const steps = [
  {
    target: '.viewSelect',
    content:
      'Choose a default view to automatically filter the dashboard or generate your own view using the filters desired.',
    disableBeacon: true,
  },
  {
    target: '.filterSection',
    content:
      'Use filters to quickly filter by Disease or use Apply Filters to filter by any of the parameters in the columns of the table.',
    disableBeacon: true,
  },
  {
    target: '.adjustButton',
    content:
      'Click this button to personalise which data is shown on the chart.',
    disableBeacon: true,
  },
  {
    target: '.filterSelects',
    content: 'Use these buttons to edit which data is shown on the chart.',
    disableBeacon: true,
  },
  {
    target: '.legendPanel',
    content: 'Tick/untick these checkboxes to show or hide specific data.',
    disableBeacon: true,
  },
  {
    target: '.exportButton',
    content:
      'Once you are satisfied with how the chart appears, you can export it to image or CSV.',
    disableBeacon: true,
  },
  {
    target: '.barHighlight',
    content:
      'Click directly on the chart to filter chart and table, unclick to remove filters.',
    disableBeacon: true,
  },
]

// --------------------------------------------------
// Helpers
// --------------------------------------------------

export const colorTheme = [
  '#6dc7bb',
  '#f0a9c1',
  '#aff8da',
  '#cea1cf',
  '#cbefbb',
  '#a7a7dc',
  '#f5f3b8',
  '#a4b6ec',
  '#e8e6ac',
  '#91bcef',
  '#fae0aa',
  '#76b2dd',
  '#ffdcaa',
  '#5fb6d7',
  '#fdbca3',
  '#7bd3f3',
  '#d89f80',
  '#83e6f2',
  '#dfb288',
  '#9bcafc',
  '#ddbd8b',
  '#c2c8ff',
  '#b7b67e',
  '#c6b7ed',
  '#d6e2a9',
  '#dbbcf0',
  '#aacd9b',
  '#edc1f1',
  '#92c094',
  '#f6bde6',
  '#aeeec9',
  '#e9b1da',
  '#99dbb9',
  '#d69ab8',
  '#b6ffee',
  '#d99ba6',
  '#82e1dd',
  '#ffbaba',
  '#6ed0d1',
  '#ffc8ed',
  '#88c9a8',
  '#fed4ff',
  '#9dcfa4',
  '#e9cfff',
  '#b3c791',
  '#c99fbe',
  '#85d6c0',
  '#ffc0c6',
  '#5eb9be',
  '#d69f8f',
  '#97eaff',
  '#baae78',
  '#a2d8ff',
  '#d9c992',
  '#bfdeff',
  '#c5a682',
  '#b1feff',
  '#d09eab',
  '#b7fff8',
  '#c7a496',
  '#acf0ff',
  '#b8ab86',
  '#ffdfff',
  '#87b696',
  '#ffd3de',
  '#6db9ab',
  '#ffd0c6',
  '#75b6c1',
  '#ffeac0',
  '#8ab1c9',
  '#fffcd7',
  '#b6a6c0',
  '#dfffe4',
  '#aea9bd',
  '#cdffef',
  '#c2a5a5',
  '#cafffe',
  '#bea892',
  '#d9ddff',
  '#a0b18b',
  '#fcecff',
  '#8bb5a2',
  '#fff2ff',
  '#abae8d',
  '#d6e9ff',
  '#ffe0c6',
  '#7db6b6',
  '#ffe5ed',
  '#8eb1c1',
  '#fdffe6',
  '#a0acc0',
  '#f7fff3',
  '#b7a7b1',
  '#d8f9ff',
  '#b4aaa2',
  '#f4feff',
  '#95b0b6',
  '#ffedde',
  '#a6aea5',
  '#f8f8ff',
]

export const formatKeyAndItem = (
  key: ResponseData,
  stackByItem: ResponseData
) => {
  let displayKey = relationGetDisplayValue(key)
  let displayStackByItem = relationGetDisplayValue(stackByItem)

  return {
    formattedKey: joinArray(displayKey),
    formattedStackByItem: joinArray(displayStackByItem),
  }
}

const joinArray = (item: CleanResponseData) => {
  return Array.isArray(item) ? item.join(', ') : item!!
}

export const invalidKeys = ['null', null, '', 0]

export function generateColorScheme(
  keys: string[],
  baseColors: string[]
): { [key: string]: string } {
  const colorScheme: { [key: string]: string } = {}
  const colorsCount = baseColors.length

  keys.forEach((key, index) => {
    const color = baseColors[index % colorsCount]
    colorScheme[key] = color
  })

  return colorScheme
}

export const combineObject = (
  acc: { [key: string]: DynamicKeyType<number> },
  formattedKey: string | number,
  formattedStackByItem: string | number,
  sumByKeyValue: any,
  isSumming: boolean
) => {
  if (
    ((typeof formattedKey === 'string' && formattedKey.length > 0) ||
      (!invalidKeys.includes(formattedKey) &&
        !invalidKeys.includes(formattedStackByItem))) &&
    (acc[formattedKey] = acc[formattedKey] || {})
  ) {
    if (isSumming) {
      acc[formattedKey][formattedStackByItem] =
        (acc[formattedKey][formattedStackByItem] || 0) + sumByKeyValue
    } else {
      acc[formattedKey][formattedStackByItem] =
        (acc[formattedKey][formattedStackByItem] || 0) + 1
    }
  }
}

export const compactLabel = (
  label: string,
  length: number,
  threshold: number
) => {
  return label.length > threshold - length + 5
    ? `${label.slice(0, threshold - length + 5)}...`
    : label
}

export const exportChartToPng = (
  chartRef: React.RefObject<HTMLCanvasElement>,
  verticalAxisLabel: string,
  transformByValueLabel: string,
  stackByValueLabel: string
) => {
  if (chartRef.current) {
    domtoimage
      .toPng(chartRef.current, {
        width: chartRef.current.width,
        height: chartRef.current.height,
        quality: 10,
        style: {
          border: 'none',
          backgroundColor: 'white',
        },
      })
      .then(function (dataUrl: any) {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = `${verticalAxisLabel} - (X-axis - ${transformByValueLabel}, Y-axis - ${stackByValueLabel}) Chart.png`
        link.click()
      })
      .catch(function (error: any) {
        console.error('Error exporting chart:', error)
      })
  }
}

export const BarTotalsLayer = (
  context: CanvasRenderingContext2D,
  props: BarCanvasCustomLayerProps<BarDatum>
) => {
  const { bars, xScale, yScale } = props
  const labelOffset = 7
  const labelFontSize = 14
  if (bars.length === 0) return null

  const totals = bars.reduce<Record<number, number>>((acc, bar) => {
    const indexValue = bar.data.indexValue
    if (!(indexValue in acc)) {
      acc[indexValue as keyof typeof acc] = 0
    }
    if (!bar.data.hidden) {
      acc[indexValue as keyof typeof acc] += bar.data.value!
    }
    return acc
  }, {})

  const bandwidth = bars[0]?.width

  return (Object.keys(totals) as any as number[]).forEach((indexValue) => {
    const x = xScale(indexValue) + bandwidth / 2
    const y = yScale(totals[indexValue]) - labelOffset

    context.font = `600 ${labelFontSize}px 'IBM Plex Sans', sans-serif`
    context.fillStyle = 'black'
    context.textAlign = 'center'
    context.fillText(`${totals[indexValue]}`, x, y - labelOffset)
  })
}
