import { Box } from '@chakra-ui/react'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { memo, useEffect, useMemo, useRef } from 'react'

import { useTableChartFilters } from 'contexts/TableChartFilters'

import { flattenPaginatedData } from 'api/helper'
import { IResponseBase, Paginated } from 'api/types'
import { mapStateFromTableStateSortBy } from 'api/useTableData'
import { useCollectionDataRaw } from 'api/useTableData/useCollectionDataNew'
import useUserViewData from 'api/useUserViewData'

import { getTableStateFromModel } from 'config/common/model/state'

import { getCustomSortOrderFromModel } from 'utils/model'

import { useTablePageData } from '../TablePageProvider'

type CustomLegendsRefT = {
  handleSpecificBarToggle: (id: string | number) => void
}

const AutoGeneratedDashboard = ({
  selectedViewIndex,
}: {
  selectedViewIndex: number
}) => {
  const { app, model, viewData, page } = useTablePageData()
  const { tableFilters, addToFilters, setTransformBy, setStackBy } =
    useTableChartFilters()

  const customLegendsRef = useRef<CustomLegendsRefT>()
  const customLegendsRef1 = useRef<CustomLegendsRefT>()

  const initialState = useMemo(
    () => ({
      ...getTableStateFromModel(model),
    }),
    [model]
  )

  const initialSortBy = initialState?.sortBy || []
  const userViewQuery = useUserViewData(app.slug, page.key, initialState)
  useEffect(() => {
    if (userViewQuery.data && selectedViewIndex > 0) {
      const selectedView = userViewQuery.data[selectedViewIndex]

      if (selectedView) {
        if (
          selectedView.view.dropdowns &&
          selectedView.view.dropdowns.transformBy &&
          selectedView.view.dropdowns.stackBy
        ) {
          setTransformBy(selectedView.view.dropdowns.transformBy)
          setStackBy(selectedView.view.dropdowns.stackBy)
        }
        addToFilters(userViewQuery.data[selectedViewIndex].view.advancedFilters)
      }
    } else if (selectedViewIndex === 0) {
      addToFilters([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewIndex])

  const collectionDataQuery = useCollectionDataRaw(
    app.slug,
    model.endpoint,
    { view: viewData.airtableName, airtableBase: viewData.airtableBase },
    {
      sortBy: [],
      pageIndex: 1,
      perPage: -1,
      extraState: {},
      globalFilter: { columns: [], searchString: '' },
      advancedFilters: tableFilters,
      schema: [],
    },
    {
      paginate: false,
      customSortOrder: getCustomSortOrderFromModel(model),
      initialSort: mapStateFromTableStateSortBy(initialSortBy),
      grouped: false,
    },
    {
      customAPIFunction: model.customAPIFunction,
    },
    {
      keepPreviousData: true,
    }
  ) as UseInfiniteQueryResult<Paginated<IResponseBase[]>, AxiosError<any>>

  const flattenedPaginatedData = flattenPaginatedData(
    collectionDataQuery.data?.pages
  )

  const chartData = flattenedPaginatedData?.results

  const loaded =
    flattenedPaginatedData?.globalLoaded ===
      flattenedPaginatedData?.globalTotal ||
    flattenedPaginatedData?.globalLoaded! >=
      flattenedPaginatedData?.globalTotal!

  const dashboard = useMemo(() => {
    if (!chartData || !page.autoGenerateDashboard) return null
    if (Array.isArray(page.autoGenerateDashboard.render)) {
      return (
        <Box display='flex' gap={1} height='100%'>
          {page.autoGenerateDashboard.render.map((element, index) => {
            return (
              <Box marginTop={2} width='50%' height={'100%'}>
                {flattenedPaginatedData.globalLoaded}
                {flattenedPaginatedData.globalTotal}

                {React.createElement(element, {
                  key: index,
                  customLegendsRef,
                  customLegendsRef1,
                  chartData,
                  model,
                  page,
                  loaded,
                  isMultiChart:
                    page.autoGenerateDashboard &&
                    Array.isArray(page.autoGenerateDashboard.render),
                })}
              </Box>
            )
          })}
        </Box>
      )
    }
    return React.createElement(page.autoGenerateDashboard.render, {
      customLegendsRef,
      customLegendsRef1,
      chartData,
      model,
      page,
      loaded,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData?.length])

  return <>{dashboard}</>
}

export default memo(AutoGeneratedDashboard)
