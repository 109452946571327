import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'

import { TableViewSelector } from 'modules/Tables/Views/TableViewSelector'

import { useFilterModal } from 'contexts/FilterModal'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import { Panel } from 'components'
import QuickFilter from 'components/PageHeader/QuickFilter'
import Filter from 'components/Table/Toolbar/FilterButton'

import { IModel } from 'interfaces/model.interface'

import useIsMobile from 'utils/useIsMobile'

import ChartFilterPill from '../ChartFilterPill/ChartFilterPill'

type FilterPanelPropTypes = {
  model: IModel<any, any>
  tableData: any
  applyQuickFilter: any
  isLoading: boolean
  views: any
  setView: any
  selectedView: any
  tableState: any
  userViewConfig: any
  useUserViewsReturn: any
}

const FilterPanel = ({
  model,
  tableData,
  applyQuickFilter,
  isLoading,
  views,
  setView,
  selectedView,
  tableState,
  userViewConfig,
  useUserViewsReturn,
}: FilterPanelPropTypes) => {
  const [isMobile] = useIsMobile()
  const { tableFilters, removeFromFilters } = useTableChartFilters()
  const {
    disclosure: { onOpen },
  } = useFilterModal()
  return (
    <Grid
      templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
      columnGap={2}
    >
      <GridItem colSpan={{ lg: model.quickFilter ? 1 : 2, xl: 1 }}>
        <Panel
          maxW='88vw'
          // overflowX='auto'
          border='1px solid'
          borderColor='gray3'
          borderRadius='8px'
          display={'flex'}
          px={2}
          py={1}
          pl={5}
          mb={2}
          className='viewSelect'
          alignItems={'center'}
        >
          <TableViewSelector
            dropdown
            views={views}
            selectedView={selectedView}
            handleSetView={setView ?? (() => {})}
            tableState={tableState}
            userViewConfig={userViewConfig}
            useUserViewsReturn={useUserViewsReturn}
          />
          {useUserViewsReturn?.selectedUserViewIndex! > 0 ? (
            <Box
              mx={5}
              mt={-1}
              width='max-content'
              id='userViewsActionsDashboard'
            />
          ) : null}
        </Panel>
      </GridItem>
      <GridItem
        colSpan={{ lg: 1, xl: 2 }}
        gap={2}
        display={{ xl: 'flex', lg: 'contents' }}
        className='filterSection'
      >
        {model?.quickFilter && (
          <Panel
            maxW={isMobile ? '88vw' : '300px'}
            flex={1}
            border='1px solid'
            borderColor='gray3'
            background='white'
            borderRadius='8px'
            display={'flex'}
            px={2}
            py={1}
            pl={5}
            mb={2}
            alignItems={'center'}
          >
            {!isLoading && (
              <QuickFilter quickFilter={applyQuickFilter} dashboard />
            )}
          </Panel>
        )}
        <GridItem
          w='100%'
          maxW={isMobile ? '88vw' : 'initial'}
          overflowX={'auto'}
          colSpan={{ lg: 2, xl: !model?.quickFilter ? 2 : 1 }}
        >
          <Panel
            border='1px solid'
            borderColor='gray3'
            background='white'
            borderRadius='8px'
            flex={1}
            display={'flex'}
            py={1}
            pl={5}
            mb={2}
            alignItems={'center'}
          >
            <Filter model={model} data={tableData ?? []} />
            <Flex
              ml={3}
              maxW={
                useUserViewsReturn?.selectedUserViewIndex! > 0 ? '65%' : '75%'
              }
              overflowY='auto'
            >
              {tableFilters.length > 0 &&
                tableFilters
                  .slice(0, 2)
                  .map((filter, index) => (
                    <ChartFilterPill
                      key={index}
                      model={model}
                      advancedFilter={filter}
                      removeFilters={removeFromFilters}
                    />
                  ))}
              {tableFilters.length > 2 && (
                <Box
                  onClick={onOpen}
                  ml={2}
                  background='yellow.400'
                  borderRadius='8px'
                  boxShadow='sm'
                  py={1}
                  px={3}
                  fontSize='xs'
                  display={'flex'}
                  cursor={'pointer'}
                >
                  +{tableFilters.length - 2}
                </Box>
              )}
            </Flex>
          </Panel>
        </GridItem>
      </GridItem>
    </Grid>
  )
}

export default FilterPanel
