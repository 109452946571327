import { Search } from '@carbon/icons-react'
import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'

import useTracking from 'tracking/useTracking'

type ReportSearchInputPropTypes = {
  showCategory: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
  isMobile?: boolean
}
export const ReportSearchInput = ({
  showCategory,
  onChange,
  onBlur,
  isMobile = false,
}: ReportSearchInputPropTypes) => {
  const [tracking] = useTracking()
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        children={
          <Icon as={Search} mt='auto' width='21px' height='21px' ml={6} />
        }
      />
      <Input
        w='full'
        border='1px solid'
        borderColor='gray3'
        backgroundColor='gray5'
        rounded='none'
        borderEndRadius={isMobile && showCategory ? 'none' : '8px'}
        borderRadius={!showCategory && isMobile ? '8px' : 'none'}
        borderBottomRadius={isMobile && !showCategory ? '8px' : 'none'}
        onClick={() =>
          tracking.filterReports({
            filter: 'byInputWords',
          })
        }
        placeholder='What are you looking for?'
        color='black'
        height='61px'
        pl='54px'
        fontWeight={600}
        fontSize='17px'
        onChange={onChange}
        onBlur={onBlur}
        _focus={{
          borderColor: 'gray3',
          boxShadow: 'none',
        }}
        _placeholder={{
          color: 'gray2',
        }}
      />
    </InputGroup>
  )
}
