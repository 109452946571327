import { Information } from '@carbon/icons-react'
import { Alert, AlertIcon, Box, Button, Spinner, Text } from '@chakra-ui/react'
import { ResponsiveBarCanvas } from '@nivo/bar'
import { capitalize } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Joyride from 'react-joyride'

import { useTableChartFilters } from 'contexts/TableChartFilters'

import { Panel } from 'components'
import { SelectOption } from 'components/Select'

import { DashboardConfigProps } from 'interfaces/navigationPage.interface'

import { compactNumberWithPrefix } from 'utils/formatNumber'
import useIsMobile from 'utils/useIsMobile'

import {
  BarReshapedDataType,
  BarTotalsLayer,
  DynamicKeyType,
  barChartCommonProperties,
  colorTheme,
  combineObject,
  compactLabel,
  formatKeyAndItem,
  invalidKeys,
  steps,
} from '../../dashboardsConfig'
import CustomLegends from './CustomLegends'
import CustomTooltip from './CustomTooltip'
import DataAdjustments from './DataAdjustments'

const UPPER_COLUMN_LIMIT = 200
const infoMessage = (total: number) =>
  `We are only displaying a subset of the data. Total length of data is ${total}, but only ${UPPER_COLUMN_LIMIT} is shown to prevent performance issues. If you wish to see the entire data set, please utilise the filters above.`

const BarChart = ({
  chartData,
  model,
  page,
  loaded,
  customLegendsRef,
  customLegendsRef1,
}: DashboardConfigProps) => {
  const [isMobile] = useIsMobile()

  const {
    verticalAxisLabel,
    filterBlacklistOptions,
    sumByKey,
    allowValuesToBeJoinedWhitelist,
    showStackBy,
    showTransformBy,
    showOccurenceThreshold,
    showSortBy,
    sortingOrders,
  } = page.autoGenerateDashboard || {}

  const { transformBy, stackBy } = useTableChartFilters()

  const columns = filterBlacklistOptions
    ? model.schema.columns.filter(
        (column) => !(filterBlacklistOptions as string[]).includes(column.key)
      )
    : model.schema.columns

  // column options for select filters
  const columnOptions = columns
    .filter((c) => c.key !== 'name')
    .map((column) => ({
      label: column.label || capitalize(column.key),
      value: column.key,
    })) as Array<SelectOption<string>>

  // ref to trigger toggling by specific bar from the bar chart component
  // const customLegendsRef = useRef<{
  //   handleSpecificBarToggle: (id: string | number) => void
  // }>()
  const chartRef = useRef<HTMLCanvasElement>(null)

  // data related state
  const [reshapedDataArr, setReshapedDataArr] = useState<BarReshapedDataType>(
    []
  )
  const [filteredReshapedDataArr, setFilteredReshapedDataArr] =
    useState<BarReshapedDataType>([])

  const [stepsEnabled, setStepsEnabled] = useState(
    localStorage.getItem('dashboardTourCompleted') !== 'true'
  )

  // chart related state
  const [totalData, setTotalData] = useState<number>(0)
  const [colors, setColors] = useState<DynamicKeyType<string>>({})
  const [uniqueKeys, setUniqueKeys] = useState<string[]>([])
  const [adjustOpen, setAdjustOpen] = useState<boolean>(false)
  // filtering state
  const [occurenceThreshold, setOccurenceThreshold] = useState<number>(1)

  const [sortBy, setSortBy] = useState<SelectOption<string>>({
    label: 'Default',
    value: '',
  })

  const sortOrder = sortingOrders
    ? sortingOrders[transformBy.value as keyof typeof sortingOrders]
    : undefined

  const legendSortOrder = sortingOrders
    ? sortingOrders[stackBy.value as keyof typeof sortingOrders]
    : undefined

  const showThresholdSlider =
    !Boolean(sumByKey) && Boolean(showOccurenceThreshold)

  const aggregateDataByAxisAndStackValues = (
    data: typeof chartData,
    transformByPropertyKey: string,
    stackByKey: string
  ): { [key: string]: DynamicKeyType<number> } => {
    const groupedData: { [key: string]: DynamicKeyType<number> } = {}

    data.forEach((item: any) => {
      const transformKey = Array.isArray(item[transformByPropertyKey])
        ? item[transformByPropertyKey]
        : [item[transformByPropertyKey]]

      const stackKey = Array.isArray(item[stackByKey])
        ? item[stackByKey]
        : [item[stackByKey]]

      const isSumming = typeof sumByKey !== 'undefined'
      const sumByKeyValue = sumByKey ? item[sumByKey] : null

      transformKey.forEach((key: any) => {
        // this if checks if the stackBy item is meant to be separated
        // if so, we enter the else and loop over each entry.
        if (
          (allowValuesToBeJoinedWhitelist as string[])?.includes(stackByKey) &&
          !invalidKeys.includes(key) &&
          !invalidKeys.includes(stackKey)
        ) {
          const { formattedKey, formattedStackByItem } = formatKeyAndItem(
            key,
            stackKey
          )
          combineObject(
            groupedData,
            formattedKey,
            formattedStackByItem,
            sumByKeyValue,
            isSumming
          )
        } else {
          stackKey.forEach((stack: any) => {
            if (!invalidKeys.includes(key) && !invalidKeys.includes(stack)) {
              const { formattedKey, formattedStackByItem } = formatKeyAndItem(
                key,
                stack
              )
              combineObject(
                groupedData,
                formattedKey,
                formattedStackByItem,
                sumByKeyValue,
                isSumming
              )
            }
          })
        }
      })
    })
    return groupedData
  }

  const transformAggregatedDataIntoCountArray = (intermediateObject: {
    [key: string]: DynamicKeyType<number>
  }) => {
    const keysArray: string[] = []
    let uniqueKeysSet: string[] = []

    const transformedArray = Object.keys(intermediateObject).map((key) => {
      const countObj = intermediateObject[key]
      const transformedCounts: DynamicKeyType<number> = Object.keys(
        countObj
      ).reduce((result: any, name) => {
        if (
          !invalidKeys.includes(name) &&
          countObj[name] >= occurenceThreshold
        ) {
          result[name] = countObj[name]
        }
        return result
      }, {})
      if (Object.keys(transformedCounts).length > 0) {
        keysArray.push(...Object.keys(transformedCounts))
        uniqueKeysSet = [...new Set(keysArray)]
      }
      return {
        key,
        ...transformedCounts,
      }
    })

    const filteredArray = transformedArray.filter(
      (obj) => Object.keys(obj).length > 1
    )

    const finalArray = sortOrder
      ? filteredArray.filter((w) => sortOrder.includes(w.key))
      : filteredArray

    setTotalData(filteredArray.length)

    return {
      filteredArray:
        finalArray.length > UPPER_COLUMN_LIMIT
          ? finalArray.slice(0, UPPER_COLUMN_LIMIT)
          : finalArray,
      uniqueKeysSet:
        filteredArray.length > UPPER_COLUMN_LIMIT
          ? uniqueKeysSet.slice(0, UPPER_COLUMN_LIMIT)
          : uniqueKeysSet,
    }
  }

  const reshapeDataForChart = useCallback(
    (data: typeof chartData) => {
      const transformByPropertyKey = transformBy.value
      const stackByKey = stackBy.value
      const intermediateObject = aggregateDataByAxisAndStackValues(
        data,
        transformByPropertyKey,
        stackByKey
      )

      const { filteredArray, uniqueKeysSet } =
        transformAggregatedDataIntoCountArray(intermediateObject)

      if (sortBy.value === '') {
        if (typeof sortOrder !== 'undefined') {
          filteredArray.sort(
            (a, b) => sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key)
          )
        } else {
          filteredArray.sort((a, b) =>
            a.key.toLowerCase().localeCompare(b.key.toLowerCase())
          )
        }
      } else if (sortBy.value === 'ALPHA') {
        filteredArray.sort((a, b) =>
          a.key.toLowerCase().localeCompare(b.key.toLowerCase())
        )
      } else if (sortBy.value === 'DESC' || sortBy.value === 'ASC') {
        filteredArray.sort((a, b) => {
          const sumA = Object.values(a)
            .filter((key) => key !== 'key')
            .reduce(
              (acc, curr) => acc + (typeof curr === 'number' ? curr : 0),
              0
            )
          const sumB = Object.values(b)
            .filter((key) => key !== 'key')
            .reduce(
              (acc, curr) => acc + (typeof curr === 'number' ? curr : 0),
              0
            )
          if (sortBy.value === 'ASC') return sumA - sumB
          return sumB - sumA
        })
      }
      generateColorsForStack(uniqueKeysSet, colorTheme)
      return { filteredArray, uniqueKeysSet }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chartData,
      occurenceThreshold,
      transformBy.value,
      stackBy.value,
      sortBy.value,
    ]
  )

  const getColor = useCallback(
    (bar: any) => colors[bar.id as keyof typeof colors],
    [colors]
  )

  const generateColorsForStack = useCallback(
    (uniqueKeys: string[], baseColors: string[]) => {
      uniqueKeys.forEach((key, index) => {
        const color = baseColors[index % baseColors.length]
        if (!colors[key]) {
          setColors((prevColorKeys) => {
            let newColorKeys = {
              ...prevColorKeys,
              [key]: color,
            }
            return newColorKeys
          })
        }
      })
    },
    [colors]
  )
  const chart = useMemo(() => {
    return (
      <ResponsiveBarCanvas
        {...barChartCommonProperties}
        data={filteredReshapedDataArr}
        keys={uniqueKeys}
        valueScale={
          sumByKey ? { type: 'symlog', constant: 1e7 } : { type: 'linear' }
        }
        colors={getColor}
        tooltip={(input) => (
          <CustomTooltip
            numElementsX={filteredReshapedDataArr.length}
            horizontalKey={transformBy.label}
            stackByKey={stackBy.label}
            sumByKey={Boolean(sumByKey)}
            {...input}
          />
        )}
        margin={{
          top: 30,
          right: 40,
          bottom: totalData < 150 ? 80 : 40,
          left: 60,
        }}
        layers={['axes', 'bars', BarTotalsLayer]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: reshapedDataArr.length > 6 ? 30 : 0,
          legend: `${transformBy.label}`,
          legendPosition: 'middle',
          legendOffset: totalData < 150 ? 70 : 25,
          format: (d) =>
            totalData < 150
              ? `${d.length > 12 ? `${d.substring(0, 12)}...` : d}`
              : '',
        }}
        axisLeft={{
          tickValues: sumByKey ? 5 : 10,
          tickSize: 5,
          tickPadding: 2,
          legend: verticalAxisLabel,
          legendPosition: 'middle',
          legendOffset: -40,
          format: (e) =>
            sumByKey
              ? compactNumberWithPrefix(Number(e))
              : Math.floor(e) === e
                ? e
                : '',
        }}
        ref={chartRef}
        label={(d) =>
          `${
            filteredReshapedDataArr.length > 5
              ? compactLabel(
                  d.id.toString(),
                  filteredReshapedDataArr.length,
                  30
                )
              : d.id.toString()
          }`
        }
        onClick={(data) => {
          customLegendsRef.current?.handleSpecificBarToggle(
            data.id,
            data.indexValue
          )
          customLegendsRef1.current?.handleSpecificBarToggle(
            data.id,
            data.indexValue
          )
        }}
      />
    )
  }, [
    customLegendsRef,
    customLegendsRef1,
    filteredReshapedDataArr,
    getColor,
    reshapedDataArr.length,
    stackBy.label,
    sumByKey,
    totalData,
    transformBy.label,
    uniqueKeys,
    verticalAxisLabel,
  ])

  useEffect(() => {
    const { filteredArray, uniqueKeysSet: uniqueKeys } =
      reshapeDataForChart(chartData)
    setReshapedDataArr(filteredArray)
    setFilteredReshapedDataArr(filteredArray)
    setUniqueKeys(uniqueKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    occurenceThreshold,
    transformBy.value,
    stackBy.value,
    sortBy.value,
  ])

  return (
    <>
      <Joyride
        callback={(e) => {
          if (
            e.step.target === '.exportButton' ||
            e.step.target === '.filterSelects'
          ) {
            setAdjustOpen(true)
          }
          if (
            e.step.target === '.barHighlight' ||
            e.step.target === '.adjustButton'
          ) {
            setAdjustOpen(false)
          }
          if (e.action === 'reset') {
            localStorage.setItem('dashboardTourCompleted', 'true')
            setStepsEnabled(false)
            setAdjustOpen(false)
          }
        }}
        continuous
        disableScrolling
        run={!isMobile && stepsEnabled && loaded && chartData.length > 0}
        steps={steps}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1000,
            },
          },
        }}
        showSkipButton={true}
        locale={{ last: 'Done' }}
        styles={{
          buttonBack: {
            color: 'black',
            fontSize: '12px',
            fontWeight: 600,
          },
          buttonSkip: {
            color: 'black',
            fontSize: '12px',
            fontWeight: 600,
          },
          buttonNext: {
            backgroundColor: '#FFED05',
            color: 'black',
            fontSize: '12px',
            fontWeight: 600,
          },
          options: {
            zIndex: 1000,
          },
        }}
      />
      <Box height='100%' display={'flex'}>
        <Panel
          height='100%'
          width='65%'
          border='1px solid'
          borderColor='gray3'
          background='white'
          borderRadius='8px'
          boxShadow='sm'
          flex={1}
          py={2}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {loaded ? (
              <>
                <DataAdjustments
                  adjustOpen={adjustOpen}
                  setAdjustOpen={setAdjustOpen}
                  showStackBy={Boolean(showStackBy)}
                  showTransformBy={Boolean(showTransformBy)}
                  showThresholdSlider={showThresholdSlider}
                  showSortBy={Boolean(showSortBy)}
                  columnOptions={columnOptions}
                  sortBy={[sortBy, setSortBy]}
                  occurenceThreshold={[
                    occurenceThreshold,
                    setOccurenceThreshold,
                  ]}
                  exportToChart={{
                    chartRef,
                    verticalAxisLabel: verticalAxisLabel!,
                  }}
                >
                  <Box
                    display={'flex'}
                    gap={2}
                    flexDir={isMobile ? 'column' : 'row'}
                  >
                    <CustomLegends
                      adjustOpen={adjustOpen}
                      columnKey={stackBy.value}
                      model={model}
                      ref={customLegendsRef}
                      colors={colors}
                      chartData={chartData}
                      stackByValue={stackBy.value}
                      loaded={Boolean(loaded)}
                      sortOrder={legendSortOrder}
                      joinedValues={allowValuesToBeJoinedWhitelist as string[]}
                    />
                    <CustomLegends
                      adjustOpen={adjustOpen}
                      columnKey={transformBy.value}
                      model={model}
                      ref={customLegendsRef1}
                      title='X-axis Options'
                      chartData={chartData}
                      stackByValue={transformBy.value}
                      loaded={Boolean(loaded)}
                      sortOrder={sortOrder}
                      joinedValues={allowValuesToBeJoinedWhitelist as string[]}
                    />
                  </Box>
                </DataAdjustments>
                {!isMobile && (
                  <Button
                    mt={'10px'}
                    className='adjustButton'
                    variant='solid'
                    size={'xs'}
                    fontSize='12px'
                    onClick={() => {
                      localStorage.removeItem('dashboardTourCompleted')
                      setStepsEnabled(true)
                    }}
                  >
                    <Box mr={1}>
                      <Information size={16} />
                    </Box>
                    View demo
                  </Button>
                )}
              </>
            ) : (
              <Spinner thickness={'4px'} size='md' mt={4} />
            )}
          </Box>
          {totalData > 100 && (
            <Box
              display={'flex'}
              alignItems='center'
              position={'relative'}
              justifyContent={'center'}
            >
              <Alert
                position='absolute'
                borderRadius={'lg'}
                width='50%'
                transition={'ease-in'}
                fontWeight='semibold'
                mt={'85px'}
                fontSize='12px'
                status='warning'
                backgroundColor={'yellow.300'}
              >
                <AlertIcon color={'yellow.800'} />
                {infoMessage(totalData)}
              </Alert>
            </Box>
          )}
          {reshapedDataArr.length > 0 ? (
            <>
              <Box
                bg='white'
                className='barHighlight'
                position={'relative'}
                h='90%'
              >
                {chart}
              </Box>
            </>
          ) : (
            <Box
              p={40}
              textAlign='center'
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              data-cy='loading'
            >
              <Text ml={2}> No data found, try adjusting the filters.</Text>
            </Box>
          )}
        </Panel>
      </Box>
    </>
  )
}

export default memo(BarChart)
