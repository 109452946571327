import { removeMarkdown } from 'utils/markdonw'
import {
  ThumbsUp,
  ThumbsDown,
  Copy,
  CheckmarkOutline,
} from '@carbon/icons-react'
import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'
const TextFeedback = ({
  sendFeedback,
}: {
  sendFeedback: (value: string) => void
}) => {
  function handleFeedback() {
    sendFeedback('')
  }
  return (
    <Popover>
      <PopoverTrigger>
        <Tooltip label='Send feedback'>
          <Button variant={'ghost'} fontWeight='normal'>
            Feedback
          </Button>
        </Tooltip>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderRadius={'xl'} p='1rem'>
          <PopoverArrow />
          <PopoverHeader borderBottom={'none'}>Feedback</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Text fontSize={'sm'} mb='1rem'>
              Write your feedback about the answer provided.
            </Text>
            <Textarea fontSize={'sm'} />
          </PopoverBody>
          <PopoverFooter border='none'>
            <Button variant={'yellow'} onClick={handleFeedback}>
              Submit
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const FeedbackBar = ({
  content,
  isUser,
  isInternal,
}: {
  content: string
  isUser: boolean
  isInternal: boolean
}) => {
  const [isCopied, setIsCopied] = useState(false)
  if (isUser || isInternal) return null

  function copyToClipboard() {
    navigator.clipboard.writeText(removeMarkdown(content))
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 5000)
  }

  function goodResponse() {}
  function badResponse() {}
  function sendFeedback(value: string) {}

  return (
    <Flex
      pos='absolute'
      bottom='0px'
      right={'0px'}
      style={{ transform: 'translate(0,60%)' }}
      bg='white'
      border='1px solid'
      borderColor={'gray.300'}
      borderRadius='md'
    >
      <Tooltip label='Copy answer to clipboard'>
        <Button onClick={copyToClipboard} variant='ghost'>
          {isCopied ? <CheckmarkOutline color='green' /> : <Copy />}
        </Button>
      </Tooltip>
      {false && (
        <>
          <Tooltip label='Good response'>
            <Button onClick={goodResponse} variant='ghost'>
              <ThumbsUp />
            </Button>
          </Tooltip>
          <Tooltip label='Bad response'>
            <Button onClick={badResponse} variant='ghost'>
              <ThumbsDown />
            </Button>
          </Tooltip>

          <TextFeedback sendFeedback={sendFeedback} />
        </>
      )}
    </Flex>
  )
}

export default FeedbackBar
