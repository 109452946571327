import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InfluenzaCommercialSlugs =
  | 'supply-pricing'
  | 'table'
  | 'pricing-table'
  | 'supply-table'
  | 'supply-dashboard'
  | 'pricing-dashboard'
  | 'production'
  | 'reports'
  | 'immunisation-schedules'
  | 'company-production'
  | 'tenders'
  | 'number-of-tenders'
  | 'doses-tendered'
  | 'value-and-price'
  | 'research-briefs'
  | 'revenue'
  | 'dashboard'
  | 'demand'
  | 'forecast'

const InfluenzaCommercialMenu: Array<
  INavigationMenu<InfluenzaCommercialSlugs>[]
> = [
  [
    {
      title: 'Demand',
      slug: 'demand',
      createdAt: new Date(2022, 6, 5),
      children: [
        {
          title: 'Demand Forecast',
          slug: 'forecast',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Immunisation Schedules',
          slug: 'immunisation-schedules',
          createdAt: new Date(2022, 6, 24),
        },
      ],
    },

    {
      title: 'Supply & Pricing',
      slug: 'supply-pricing',
      createdAt: new Date(2022, 5, 22),
      children: [
        {
          title: 'Supply Table',
          slug: 'supply-table',
          createdAt: new Date(2024, 7, 15),
        },
        {
          title: 'Supply Dashboard',
          slug: 'supply-dashboard',
          createdAt: new Date(2022, 5, 22),
        },
        {
          title: 'Pricing Table',
          slug: 'pricing-table',
          createdAt: new Date(2024, 7, 15),
        },
        {
          title: 'Pricing Dashboard',
          slug: 'pricing-dashboard',
          createdAt: new Date(2022, 5, 22),
        },
      ],
    },
    {
      title: 'Revenue',
      slug: 'revenue',
      createdAt: new Date(2022, 12, 20),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 7, 15),
        },
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2023, 12, 20),
        },
      ],
    },
    {
      title: 'Tenders',
      slug: 'tenders',
      createdAt: new Date(2022, 6, 5),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 6, 5),
        },
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2023, 1, 18),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2021, 9, 12),
      children: [
        {
          title: 'Reports',
          slug: 'reports',
          createdAt: new Date(2021, 9, 12),
          description: 'In-depth insights on topics relevant to Influenza',
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2024, 1, 25),
        },
      ],
    },
  ],
]

export { InfluenzaCommercialMenu }
