import { Information } from '@carbon/icons-react'
import { SettingsAdjust } from '@carbon/icons-react'
import {
  Box,
  Button,
  Fade,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { useExportModal } from 'contexts/ExportModal'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import { Select, SelectOption } from 'components/Select'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'
import useKeyPress from 'utils/useKeyPress'

import { exportChartToPng } from '../../dashboardsConfig'

type SetStateType<T> = React.Dispatch<React.SetStateAction<T>>

interface DataAdjustmentsProps {
  occurenceThreshold?: [number, SetStateType<number>]
  sortBy?: [SelectOption<string>, SetStateType<SelectOption<string>>]
  showThresholdSlider: boolean
  showStackBy: boolean
  showTransformBy: boolean
  showSortBy: boolean
  columnOptions: Array<SelectOption<string>>
  exportToChart: {
    chartRef: React.RefObject<HTMLCanvasElement>
    verticalAxisLabel: string
  }
  showExport?: boolean
  adjustOpen: boolean
  setAdjustOpen: SetStateType<boolean>
}

const DataAdjustments = ({
  occurenceThreshold,
  showThresholdSlider,
  showStackBy,
  sortBy,
  showSortBy,
  showTransformBy,
  columnOptions,
  exportToChart,
  showExport = true,
  children,
  adjustOpen,
  setAdjustOpen,
}: DataAdjustmentsProps & { children?: React.ReactNode }) => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const { setStackBy, stackBy, setTransformBy, transformBy } =
    useTableChartFilters()
  const { openModal } = useExportModal()

  const [sortByValue, setSortBy] = sortBy || []
  const [occurenceThresholdValue, setOccurenceThreshold] =
    occurenceThreshold || []
  const { chartRef, verticalAxisLabel } = exportToChart
  useKeyPress('Escape', () => {
    setAdjustOpen(false)
  })
  return (
    <>
      <Box
        display={adjustOpen ? 'block' : 'none'}
        position={'fixed'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        zIndex={998}
      >
        <Fade in={adjustOpen}>
          <Box
            bg='black'
            opacity={'50%'}
            position={'absolute'}
            top={0}
            left={0}
            width={'100%'}
            height={'100%'}
            cursor={'pointer'}
            onClick={() => setAdjustOpen(!adjustOpen)}
          ></Box>
          <Box
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            bg={'white'}
            padding={'20px'}
            rounded='md'
            maxWidth={'1000px'}
            width={isMobile ? '95vw' : '50%'}
            overflowY={'auto'}
            maxH='700px'
            transform='translate(-50%, -50%)'
          >
            <Box display={'flex'} mb='20px' alignItems={'center'}>
              <Text width='100%' fontWeight={'bold'}>
                Data Adjustments
              </Text>
              <Box className='exportButton' display={'flex'}>
                {showExport && (
                  <>
                    <Button
                      mt={2}
                      mr={3}
                      size={'xs'}
                      variant={'solid'}
                      onClick={() => openModal()}
                    >
                      Export Data
                    </Button>
                    <Button
                      mt={2}
                      mr={3}
                      size={'xs'}
                      variant={'solid'}
                      onClick={() => {
                        tracking.exportDashboardImage({})
                        exportChartToPng(
                          chartRef,
                          verticalAxisLabel,
                          transformBy.label,
                          stackBy.label
                        )
                      }}
                    >
                      Export as Image
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <>
                <Box
                  width={'100%'}
                  px={5}
                  display={'flex'}
                  flexDirection={isMobile ? 'column' : 'row'}
                  className='filterSelects'
                  gap={isMobile ? 4 : 0}
                >
                  {showTransformBy && (
                    <Box width={isMobile ? '100%' : '20%'}>
                      <Text fontSize={'12px'}>Horizontal Axis</Text>
                      <Select
                        value={transformBy}
                        onChange={(e: { label: string; value: string }) => {
                          tracking.changedFilterByDropdowns({
                            columnName: e.label,
                            filterType: 'Horizontal axis',
                          })
                          setTransformBy(e)
                        }}
                        options={columnOptions}
                      />
                    </Box>
                  )}
                  {showStackBy && (
                    <Box
                      width={isMobile ? '100%' : '20%'}
                      ml={isMobile ? 0 : 4}
                    >
                      <Text fontSize={'12px'}>Stack by</Text>
                      <Select
                        value={stackBy}
                        onChange={(e: { label: string; value: string }) => {
                          tracking.changedFilterByDropdowns({
                            columnName: e.label,
                            filterType: 'Stack by',
                          })
                          setStackBy(e)
                        }}
                        options={columnOptions}
                      />
                    </Box>
                  )}
                  {showSortBy && (
                    <Box
                      width={isMobile ? '100%' : '20%'}
                      ml={isMobile ? 0 : 4}
                    >
                      <Text fontSize={'12px'}>Sort by</Text>
                      <Select
                        value={sortByValue}
                        onChange={(e: { label: string; value: string }) => {
                          tracking.changedDashboardSortOrder({ order: e.label })
                          setSortBy && setSortBy(e)
                        }}
                        options={[
                          {
                            label: 'Default',
                            value: '',
                          },
                          {
                            label: 'Alphabetical',
                            value: 'ALPHA',
                          },
                          {
                            label: 'Ascending',
                            value: 'ASC',
                          },
                          {
                            label: 'Descending',
                            value: 'DESC',
                          },
                        ]}
                      />
                    </Box>
                  )}
                  {showThresholdSlider && (
                    <Box
                      display={'flex'}
                      width={isMobile ? '100%' : '20%'}
                      ml={isMobile ? 0 : 10}
                      mt={2}
                    >
                      <Box>
                        <Text fontSize={'12px'} display='flex'>
                          {`Show results with only
              ${occurenceThresholdValue}
               occurences`}
                        </Text>
                        <Slider
                          width={'85%'}
                          color={'yellow.500'}
                          min={1}
                          max={100}
                          aria-label='slider-ex-1'
                          onChange={setOccurenceThreshold}
                          defaultValue={occurenceThresholdValue}
                        >
                          <SliderTrack>
                            <SliderFilledTrack bg='yellow.500' />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                      </Box>
                      <Tooltip
                        label={`Occurence works by calculating how many times a particular data point 
            appears for the chosen stack by value. For example "Name" for horizontal axes and 
            "Phase" for stack by will count how many times that particular name appears for a specific phase.`}
                      >
                        <Information style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </>
              <Box p='20px' className='legendPanel'>
                {children}
              </Box>
            </Box>
            <Button
              variant={'yellow'}
              onClick={() => setAdjustOpen(!adjustOpen)}
              float={'right'}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Box>
      <Button
        className='adjustButton'
        variant='yellow'
        size={'xs'}
        m={2}
        ml={4}
        onClick={() => {
          tracking.adjustModalOpen({})
          setAdjustOpen(!adjustOpen)
        }}
        fontSize='12px'
      >
        <Box mr={1}>
          <SettingsAdjust size={16} />
        </Box>
        Edit Graph & Legend
      </Button>
    </>
  )
}

export default DataAdjustments
