const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
const ISSUER = process.env.REACT_APP_OKTA_ISSUER
const REDIRECT_URI = window.location.origin + '/implicit/callback'

const config = {
  authority: ISSUER,
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  scope: 'openid profile email groups',
  post_logout_redirect_uri: `${window.location.origin}/`,
}

export default {
  resourceServer: {},
  config,
}
