import { Box, Text } from '@chakra-ui/react'

import InputSection from './InputSection'

const BottomSection = ({
  setQuery,
  search,
  query,
}: {
  setQuery: React.Dispatch<React.SetStateAction<string>>
  search: (forceQuery?: string, _id?: string) => Promise<void>
  query: string
}) => {
  return (
    <Box
      pos='absolute'
      bottom={'35px'}
      m='auto'
      left={'50%'}
      transform='translate(-50%,0)'
    >
      <Box bg='#e9e7ef' borderTopRadius={'8px'} borderColor='#cfcce0' mx='8px'>
        <Text fontSize={'xs'} px='1rem' py='4px' color={'#434032'}>
          LLM currently answers might not be most up to date, you can check the
          latest data on the platform on consult with an analyst.
        </Text>
      </Box>
      <InputSection
        setQuery={setQuery}
        search={search}
        query={query}
        placeholder={`Search on Airfinity Data`}
      />
      <Text variant={'body3'} textAlign='center' mb={'-20px'} pt='4px'>
        Airfinity's AI can make mistakes. Consider checking the reports
        directly.
      </Text>
    </Box>
  )
}

export default BottomSection
