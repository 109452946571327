import { Menu } from '@carbon/icons-react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AppParams } from 'routes/utils'

import { AuthContext } from 'contexts'

import { Footer } from 'components'

import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import useTracking from 'tracking/useTracking'

import { apps as APPS, apps, appsListUnion } from 'config/apps'

import { groupMenu } from 'utils/menu'
import resolvePath from 'utils/resolvePath'

import FlavourSelector from './FlavourSelector'
import ExpandableNavigationEntry from './SidebarNavigation/NavigationEntry/ExpandableNavigationEntry'
import SimpleNavigationEntry from './SidebarNavigation/NavigationEntry/SimpleNavigationEntry'

const MobileCurrentAppNavLinks = ({ appParams }: { appParams: AppParams }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const selectedAppSlug = appParams.app as appsListUnion

  const selectedApp = APPS[selectedAppSlug]
  const menus = selectedApp.menu.flat()

  const { pageSlug } = appParams

  const selectedMenu = menus.find((menu) => menu.slug === pageSlug)

  const groupedMenu = groupMenu(menus)

  const [selectedGroup, setSelectedGroup] = useState(
    selectedMenu
      ? selectedMenu.grouping
        ? Object.keys(groupedMenu).indexOf(selectedMenu?.grouping)
        : Object.keys(groupedMenu).indexOf('Default')
      : 0
  )

  const hasMenu = !!Object.keys(groupedMenu)?.length

  const visibleMenus = menus.filter((menu) => !menu.isHidden)
  return (
    <>
      <IconButton
        aria-label='Navigation menu'
        icon={<Menu size={32} />}
        position='absolute'
        colorScheme='yellow'
        bottom='10px'
        rounded='full'
        variant='solid'
        fontSize='30px'
        left={'15px'}
        width='55px'
        height='55px'
        color='black'
        zIndex={100}
        p={3}
        onClick={onOpen}
      />
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent maxH={'600px'}>
          <DrawerHeader>
            <FlavourSelector isMobile appParams={appParams} />
          </DrawerHeader>
          <DrawerBody maxH={'550px'} overflowY={'auto'}>
            <Box>
              {hasMenu && Object.keys(groupedMenu).length > 1 && (
                <Flex w='100%'>
                  {Object.keys(groupedMenu).map((groupName, i) => (
                    <Flex
                      key={i}
                      flex={1}
                      p={1}
                      alignItems='center'
                      justifyContent='center'
                      cursor='pointer'
                      _hover={{ bg: 'gray.600' }}
                      bg={selectedGroup === i ? 'yellow.500' : 'inherit'}
                      onClick={() => setSelectedGroup(i)}
                      borderBottom='1px solid'
                      borderColor='gray.500'
                      color={selectedGroup === i ? 'inherit' : 'white'}
                    >
                      {groupName}
                    </Flex>
                  ))}
                </Flex>
              )}
              <Box>
                {hasMenu && Object.keys(groupedMenu).length > 1
                  ? Object.values(groupedMenu)[selectedGroup].map((menu, i) => {
                      return (
                        <MobileNavigationEntry
                          key={i}
                          appParams={appParams}
                          menu={menu}
                        />
                      )
                    })
                  : visibleMenus.map((menu, i) => {
                      return (
                        <MobileNavigationEntry
                          key={i}
                          appParams={appParams}
                          menu={menu}
                          isFirst={i === 0}
                          isLast={i === visibleMenus.length - 1}
                        />
                      )
                    })}
              </Box>
            </Box>
          </DrawerBody>
          <DrawerFooter py='-30px'>
            <Footer fromNav />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileCurrentAppNavLinks

const MobileNavigationEntry = ({
  appParams,
  menu,
  isFirst,
  isLast,
}: {
  appParams: AppParams
  menu: INavigationMenu<any>
  isFirst?: boolean
  isLast?: boolean
}) => {
  const { userInfo } = useContext(AuthContext)
  const [tracking] = useTracking()
  const location = useLocation()

  const { app, pageSlug } = appParams
  const selectedApp = apps[app as appsListUnion]

  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess =
    !menu.isPremium ||
    (menu.isPremium && userInfo?.groups.includes(premiumGroup[0]))

  if (menu.children && premiumAccess) {
    const basePath = resolvePath([selectedApp.slug, menu.slug])
    const defaultIsOpen =
      menu.defaultOpen || location.pathname.includes(basePath)
    return (
      <ExpandableNavigationEntry
        isFirst={isFirst}
        isLast={isLast}
        appParams={appParams}
        menu={menu}
        defaultIsOpen={defaultIsOpen}
        onSelect={(childMenuSlug) => {
          tracking.openAppSubPage({
            app: selectedApp.slug,
            page: menu.slug,
            subPage: childMenuSlug,
          })
        }}
      />
    )
  }

  const isActive =
    pageSlug === menu.slug || (pageSlug === undefined && menu.slug === '')

  return (
    <SimpleNavigationEntry
      isFirst={isFirst}
      isLast={isLast}
      menu={menu}
      isActive={isActive}
      link={`/${app}/${menu.slug}`}
      onSelect={() => {
        tracking.openAppPage({
          app: selectedApp.slug,
          page: menu.slug,
        })
      }}
      hasAccess={premiumAccess}
    />
  )
}
