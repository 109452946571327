import { Box, Flex, Heading, Skeleton, Text } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import { memo, useEffect, useState, Component, ErrorInfo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Document, Page } from 'react-pdf'
import html from 'rehype-parse'
// @ts-ignore
import rehypeTruncate from 'rehype-truncate'

import { ViewPdfReport } from 'components'
import { usePdfData } from 'components/PdfDownload/usePdfData'

import { ReportSearchType } from 'api/types'

import useTracking from 'tracking/useTracking'

import { SearchResultsCount } from './SearchResultsCount'

type PropTypes = {
  reportSearchData?: ReportSearchType[]
  searchString: string
}

const defaultShown = 3

export default function SearchReports({
  reportSearchData = [],
  searchString,
}: PropTypes) {
  const [totalShown, setTotalShown] = useState(defaultShown)

  if (reportSearchData?.length === 0) {
    return null
  }

  return (
    <Box mb={8}>
      <SearchResultsCount
        searchString={searchString}
        count={reportSearchData?.length ?? 0}
      />
      <Box display='flex' alignItems='center' mb='12px'>
        <Heading fontSize='18px' fontWeight={600} color='primary'>
          Reports
        </Heading>
      </Box>
      {reportSearchData.slice(0, totalShown).map((reportData, i) => {
        const url = reportData.fileUrl

        return (
          <Box key={i}>
            <ViewPdfReport
              key={url}
              url={url}
              pageIndex={reportData.pages[0]}
              searchString={searchString}
              triggerComponent={({ handleClick }) => (
                <SearchReportEntry
                  url={url}
                  reportData={reportData}
                  handleClick={handleClick}
                />
              )}
            />
          </Box>
        )
      })}
      {reportSearchData?.length > totalShown && (
        <Box textAlign='center'>
          <Text
            color='primary'
            fontSize='14px'
            textDecor='underline'
            fontWeight={500}
            cursor='pointer'
            display='inline'
            onClick={() => setTotalShown((x) => x + defaultShown)}
          >
            View more
          </Text>
        </Box>
      )}
    </Box>
  )
}

type SearchReportEntryPropTypes = {
  url: string
  reportData: ReportSearchType
  handleClick: () => void
}

const SearchReportEntry = memo(
  ({ url, reportData, handleClick }: SearchReportEntryPropTypes) => {
    const { data: pdfData, refetch: loadPdf } = usePdfData(url)

    const [tracking] = useTracking()

    useEffect(() => {
      loadPdf()
    }, [loadPdf])

    return (
      <Box
        display='flex-inline'
        onClick={() => {
          tracking.openReport({
            report: reportData.fileName,
            type: 'highlight',
          })
          handleClick()
        }}
        cursor='pointer'
        py={3}
        _hover={{
          bg: 'gray.50',
        }}
      >
        <Flex>
          <Box w='100%' mr='24px'>
            <Flex justifyContent='space-between'>
              <Text fontWeight='semibold' fontSize='14px' color='primary'>
                {reportData.fileName}
              </Text>
              <Text
                fontSize='13px'
                color='gray1'
                fontWeight='400'
                whiteSpace='nowrap'
                pl={2}
              >
                {formatDistanceToNow(new Date(reportData.date), {
                  addSuffix: true,
                })}
              </Text>
            </Flex>
            <Box mb='4px' />
            <Text fontWeight='400' fontSize='14px' color='gray1'>
              <ReactMarkdown rehypePlugins={[html, rehypeTruncate]}>
                {reportData.content}
              </ReactMarkdown>
            </Text>
            <Box mb='4px' />
            <Text fontWeight='400' fontSize='13px' color='data6'>
              {reportData.pages.length} keywords found
            </Text>
          </Box>
          {pdfData && (
            <PdfErrorBoundary>
              <Document
                file={{ data: pdfData }}
                loading={<Skeleton width='150px' height='80px' />}
              >
                <Page
                  width={150}
                  pageNumber={reportData.pages[0]}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            </PdfErrorBoundary>
          )}
        </Flex>
      </Box>
    )
  }
)

interface Props {
  children?: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  info: ErrorInfo | null
}

class PdfErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
  }
  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
    })
  }
  render() {
    if (this.state.hasError) {
      return <Skeleton width='150px' height='80px' />
    }
    return this.props.children
  }
}
