import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { Covid19CommercialTherapeuticsSlugs } from './Covid19CommercialTherapeuticsMenu'
import {
  TreatmentEligiblePopulationModel,
  TreatmentManufacturingAndProductionDealsModel,
  TreatmentRevenueModel,
  TreatmentSupplyDealsDeliveriesDonationsModel,
  TreatmentSupplyDealsPriceOrientedModel,
  TreatmentSupplyDealsSupplyOrientedModel,
} from './Covid19CommercialTherapeuticsModels'
import warningMessages from './Covid19CommercialTherapeuticsWarningMessages'

type Covid19CommercialTherapeuticsPage<TModel extends IModel<any>> =
  ISingleNavigationPage<Covid19CommercialTherapeuticsSlugs, TModel>

const DealsSupply: Covid19CommercialTherapeuticsPage<
  typeof TreatmentSupplyDealsDeliveriesDonationsModel
> = {
  key: 'Deals',
  path: ['supply', 'supply-deals-table'],
  model: {
    ...TreatmentSupplyDealsDeliveriesDonationsModel,
    warningMessage: warningMessages.supplyDeals,
    name: 'Supply Deals',
    detailViewType: 'Generic',
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'covid_commercial_deals_treatment_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Therapeutics Supply Oriented',
      airtableName: 'covid_commercial_deals_treatment_supply',
      airtableBase: 'lzdb',
      overrideModel: TreatmentSupplyDealsSupplyOrientedModel,
    },
    {
      name: 'Therapeutics Price Oriented',
      airtableName: 'covid_commercial_supply_deals_treatment_price',
      airtableBase: 'lzdb',
      overrideModel: TreatmentSupplyDealsPriceOrientedModel,
    },
  ],
}

const DealsManufacturingAndProductionDeals: Covid19CommercialTherapeuticsPage<
  typeof TreatmentManufacturingAndProductionDealsModel
> = {
  key: 'DealsManufacturingAndProductionDeals',
  path: ['manufacturing-and-production', 'production-agreements-table'],
  model: TreatmentManufacturingAndProductionDealsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'tt_production_api',
      airtableBase: 'idtreatments360',
    },
  ],
}

const EligiblePopulation: Covid19CommercialTherapeuticsPage<
  typeof TreatmentEligiblePopulationModel
> = {
  key: 'EligiblePopulation',
  path: ['demand', 'eligible-population'],
  model: {
    ...TreatmentEligiblePopulationModel,
    warningMessage: warningMessages.eligiblePopulation,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableBase: 'lzdb',
      airtableName: 'COVID-19 Therapeutics Commercial Eligible Population',
    },
  ],
}

export const Revenue: Covid19CommercialTherapeuticsPage<
  typeof TreatmentRevenueModel
> = {
  key: 'Revenue',
  path: ['revenue', 'table'],
  model: {
    ...TreatmentRevenueModel,
    warningMessage: warningMessages.revenue,
    newNote: {
      text: warningMessages.revenueNewNote,
    },
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableBase: 'lzdb',
      airtableName: 'covid_commercial_revenue_treatment',
    },
  ],
}

export const Covid19CommercialTherapeuticsPages: INavigationPages<Covid19CommercialTherapeuticsSlugs> =
  {
    DealsSupply,
    DealsManufacturingAndProductionDeals,
    EligiblePopulation,
    Revenue,
  }
