import { useQuery } from '@tanstack/react-query'

import useAxios from '../useAxios'

const PRODUCT_TO_URL = {
  forecast: '/forecast/api/schema/?format=json',
  'epid-id': '/infectious-epid-api/api/schema/?format=json',
  outbreak: '/outbreak-api/api/schema/?format=json',
  report: '/report-api/api/schema/?format=json',
}

export default function useOpenAPISchema({ product }) {
  const axios = useAxios()
  const url = PRODUCT_TO_URL[product]
  return useQuery(['apiSpec'], async () => {
    const res = await axios(url)

    return res.data
  })
}
