import { Box, Flex, Text, Image, Grid } from '@chakra-ui/react'

import AiLogo from 'components/Logo/airfinity-intelligence.svg'

const exampleQuestions = [
  'Where are mpox clade Ib cases being detected.',
  // 'Which countries have reported pertussis cases in 2024?',
  'Is there risk of dengue in the UK?',
  'Why is mpox clade I dangerous?',
  'Where has wild poliovirus not been eradicated?',
  // 'Where is the risk of MERS-CoV the highest?',
  // 'How many press releases are for Mpox?',
  // 'How many news are from The telegraph? ',
  // 'How many Cholera outbreaks happen in Estonia?',
  // 'Which outbreak disease is the most common in Europe?',
]

const SuggestedQuestionCard = ({
  question,
  onClick,
}: {
  question: string
  onClick: (question: string) => void
}) => {
  return (
    <Box
      border='1px solid'
      borderColor='gray.200'
      rounded='md'
      p='12px'
      bg='gray.100'
      fontSize={'sm'}
      _hover={{
        bg: 'gray.50',
      }}
      cursor='pointer'
      onClick={() => onClick(question)}
    >
      {question}
    </Box>
  )
}

const EmptyScreen = ({
  handleSuggestion,
}: {
  handleSuggestion: (question: string) => void
}) => {
  return (
    <Box
      h='100%'
      display={'flex'}
      flexDir={'column'}
      justifyContent='space-between'
      w='100%'
      bg='transparent'
    >
      <Box
        h='100%'
        display={'flex'}
        justifyContent='center'
        alignItems='center'
        flexDir={'column'}
        gap='1rem'
      >
        <Image src={AiLogo} w='300px' />
        <Text fontSize={'xl'} fontWeight={'semibold'}>
          How can I help you today?
        </Text>
        <Flex alignItems={'center'} flexDir={'column'}>
          <Text color={'gray.600'} as='i'>
            The question will be answered using Reports and Alerts.
          </Text>
        </Flex>
      </Box>
      <Grid gridTemplateColumns={'1fr 1fr'} gap='0.5rem' mb='1rem' mx='8px'>
        {exampleQuestions.map((exampleQuestion, index) => {
          return (
            <SuggestedQuestionCard
              key={index}
              question={exampleQuestion}
              onClick={handleSuggestion}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

export default EmptyScreen
