import TreatmentDetailView from 'routes/apps/treatments/TreatmentRanking'

import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  CandidateRankingModel,
  ClinicalTrialsModel,
  CountriesModel,
  MediaModel,
  PresentationsModel,
  PressReleasesModel,
  TweetsModel,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { TreatmentStudyDataMetaModel } from 'config/common/baseModel/TreatmentStudyDataMeta'
import { customFilterNaNGraphData } from 'config/common/transformValue/charts'

import { groupByFnDefault } from 'utils/grouping'
import {
  addOrOverrideColumn,
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

const PhaseSortingObject = {
  sortOrderObject: [
    'null',
    'Validation',
    'Not Applicable',
    'Unknown',
    'Inactive',
    'Discontinued',
    'Paused',
    'Product Launch',
    'Approved',
    'Phase IV',
    'Preclinical',
    'Phase 0',
    'Phase I',
    'Phase I/II',
    'Phase II',
    'Phase II/III',
    'Phase III',
    'Phase III/IV',
  ],
}

export const TreatmentCandidateRankingModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'treatmentNameAlias',
    'luCentralDisease',
    'lookupAfTrTypeFromBnf',
    'targetTreatments',
    'organisations',
    'researchInstitutions',
    'lookupAfTrRouteOfAdministrationFromBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'activeDevelopment',
    'lookupAfTrRepurposedFromBnf',
    'patientSetting',
    'stageOfIntervention',
    'treatmentTiming',
    'lookupAfTrPharmacompassLinkFromBnf',
    'approvalCountry',
  ]),
  renameColumn('lookupAfTrTypeFromBnf', 'Technology Type'),
  renameColumn('targetTreatments', 'Target Domains'),
  renameColumn('organisations', 'Primary Developers'),
  renameColumn('researchInstitutions', 'Affiliated Institutions'),
  renameColumn(
    'lookupAfTrRouteOfAdministrationFromBnf',
    'Routes of Administration'
  ),
  renameColumn('lookupAfTrClinicalTrialStageFromBnf', 'Phase'),
  updateColumnAttributes(
    'lookupAfTrClinicalTrialStageFromBnf',
    PhaseSortingObject
  )
)({
  ...CandidateRankingModel,
  name: 'Treatments',
  renderDetailView: TreatmentDetailView,
  defaultSortObject: [
    {
      id: 'lookupAfTrClinicalTrialStageFromBnf',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'linkAfTrUniqueTreatmentNameToBnf',
      sortOrder: SortOrders.ASC,
    },
  ],
})

export const TreatmentCandidateRankingAntiviralsModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'treatmentNameAlias',
    'luCentralDisease',
    'lookupAfTrTypeFromBnf',
    'organisations',
    'researchInstitutions',
    'targetTreatments',
    'antiviralMechanismClass',
    'lookupAfTrRouteOfAdministrationFromBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'latestPhaseManualDetails',
    'lookupAfTrRepurposedFromBnf',
    'patientSetting',
    'stageOfIntervention',
    'treatmentTiming',
    'lookupAfTrPharmacompassLinkFromBnf',
    'approvalCountry',
  ]),
  updateColumnAttributes(
    'lookupAfTrClinicalTrialStageFromBnf',
    PhaseSortingObject
  )
)({
  ...CandidateRankingModel,
  name: 'Antivirals',
  renderDetailView: TreatmentDetailView,
  defaultSortKey: 'covidPriority',
})

export const TreatmentCandidateRankingPassiveImmunotherapiesModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'treatmentNameAlias',
    'luCentralDisease',
    'lookupAfTrTypeFromBnf',
    'afAntibodyType',
    'targetTreatments',
    'organisations',
    'researchInstitutions',
    'lookupAfTrRouteOfAdministrationFromBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'latestPhaseManualDetails',
    'lookupAfTrRepurposedFromBnf',
    'patientSetting',
    'stageOfIntervention',
    'treatmentTiming',
    'lookupAfTrPharmacompassLinkFromBnf',
    'approvalCountry',
  ]),
  updateColumnAttributes(
    'lookupAfTrClinicalTrialStageFromBnf',
    PhaseSortingObject
  )
)({ ...CandidateRankingModel, name: 'Passive Immunotherapies' })
export const TreatmentCandidateLongCovidModel = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'treatmentNameAlias',
    'luCentralDisease',
    'lookupAfTrTypeFromBnf',
    'targetTreatments',
    'organisations',
    'researchInstitutions',
    'lookupAfTrRouteOfAdministrationFromBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'lookupAfTrRepurposedFromBnf',
    'patientSetting',
    'stageOfIntervention',
    'treatmentTiming',
    'linkClinicalTrials',
    'lookupAfTrPharmacompassLinkFromBnf',
  ]),
  updateColumnAttributes(
    'lookupAfTrClinicalTrialStageFromBnf',
    PhaseSortingObject
  )
)({ ...CandidateRankingModel, name: 'Long Covid' })

export const TreatmentPressReleasesModel = compose<typeof PressReleasesModel>(
  whiteListColumn([
    'title',
    'datePublished',
    'afTopic',
    'sourceUrl',
    'afUniqueTreatmentName',
    'description',
    'company',
    'afTags',
  ]),
  renameColumn('afUniqueTreatmentName', 'Candidate')
)(PressReleasesModel)

export const TreatmentCandidatePapersModel = whiteListColumn<
  typeof PresentationsModel
>([
  'title',
  'journals',
  'publicationDate',
  'candidates',
  'linkAfTrUniqueTreatmentNameToBnfFromCandidateList',
  'topics',
  'abstract',
  'articleType',
  'journalImpactScore',
  'articleLink',
])({
  ...PresentationsModel,
  name: 'Publications',
  endpoint: 'publication',
})

export const TreatmentClinicalTrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'govId',
    'title',
    'acronym',
    'afUniqueTreatmentName',
    'name',
    'linkInfectiousDisease',
    'linkFundingBodyOrg',
    'phasesClean',
    'status',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'studyTypeClean',
    'ages',
    'stageOfIntervention',
    'patientSetting',
    'routesOfAdministrationClinicalTrial',
    'primaryOutcomeMeasures',
    'locations',
    'url',
  ]),
  renameColumn('updatedAt', 'Updated'),
  renameColumn('linkFundingBodyOrg', 'Sponsors'),
  renameColumn('phasesClean', 'Phase'),
  renameColumn('afUniqueTreatmentName', 'Candidate')
)({
  ...ClinicalTrialsModel,
  displayKey: 'govId',
  defaultSortKey: 'updatedAt',
  defaultSortOrder: SortOrders.DESC,
  searchField: 'name',
  endpoint: 'clinical-trial',
})

export const TreatmentResultsApprovedCandidatesModel = compose<
  typeof TreatmentStudyDataMetaModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'linkInfectiousDisease',
    'treatmentType',
    'newOverallResultsSummary',
    'interventionRelatedAe',
    'govId',
    'urlFromClinicalTrials',
    'titleFromClinicalTrials',
    'acronym',
    'phasesCleanFromClinicalTrials',
    'patientSettingFromClinicalTrials',
    'inpatientOutpatient',
    'publicationType',
    'articleLink',
    'linkFromMedia',
    'linkPress',
    'sourceDate',
  ]),
  renameColumn('linkAfTrUniqueTreatmentNameToBnf', 'Candidate'),
  renameColumn('treatmentType', 'Technology Type'),
  renameColumn('interventionRelatedAe', 'Adverse Events'),
  renameColumn('patientSettingFromClinicalTrials', 'Stage of Intervention'),
  renameColumn('articleLink', 'Publication Link'),
  renameColumn('linkFromMedia', 'Media Link')
)({
  ...TreatmentStudyDataMetaModel,
  name: 'Candidate Results',
  defaultSortKey: 'sourceDate',
  defaultSortOrder: SortOrders.DESC,
})
export const TreatmentResultsLongCovidApprovedCandidatesModel = compose<
  typeof TreatmentStudyDataMetaModel
>(
  whiteListColumn([
    'dataType',
    'linkAfTrUniqueTreatmentNameToBnf',
    'lookupAfTrClinicalTrialStageFromBnf',
    'linkInfectiousDisease',
    'treatmentType',
    'newOverallResultsSummary',
    'interventionRelatedAe',
    'govId',
    'urlFromClinicalTrials',
    'titleFromClinicalTrials',
    'acronym',
    'phasesCleanFromClinicalTrials',
    'stageOfInterventionFromClinicalTrials',
    'patientSettingFromClinicalTrials',
    'publicationType',
    'articleLink',
    'linkFromMedia',
    'linkPress',
    'sourceDate',
  ]),
  renameColumn('patientSettingFromClinicalTrials', 'Patient Setting'),
  renameColumn('linkAfTrUniqueTreatmentNameToBnf', 'Candidate'),
  renameColumn('treatmentType', 'Technology Type'),
  renameColumn('interventionRelatedAe', 'Adverse Events'),
  renameColumn('articleLink', 'Publication Link'),
  renameColumn('linkFromMedia', 'Media Link')
)({
  ...TreatmentStudyDataMetaModel,
  name: 'Candidate Results',
  defaultSortKey: 'sourceDate',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentApprovalsOrRejectionsDealsModelByTreatment = compose<
  typeof ApprovalsModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    'parentChild',
    // START OF CHILD
    'countryOrganisationGrantingDecision',
    'organisationsRegulators',
    'approvalType',
    'indication',
    'patientSetting',
    'pressReleases',
    'media',
    'supportingDocumentsUrlApprovalsFda',
    'clinicalTrials',
    'afDecisionDate',
    // END OF CHILD
  ]),
  renameColumn(
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    'Treatment Name'
  ),
  renameColumn('organisationsRegulators', 'Regulatory body')
)({
  ...ApprovalsModel,
  name: 'By Treatment',
  grouping: {
    groupBy: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    parentColumns: ['linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'],
    groupByFn: groupByFnDefault,
  },
  defaultSortKey: 'afDealId',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentApprovalsProphylaxis = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    'parentChild',
    // START OF CHILD
    'countryOrganisationGrantingDecision',
    'organisationsRegulators',
    'approvalType',
    'patientSetting',
    'pressReleases',
    'media',
    'clinicalTrials',
    'afDecisionDate',
    // END OF CHILD
  ])
)({
  ...ApprovalsModel,
  name: 'By Treatment',
  grouping: {
    groupBy: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    parentColumns: ['linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'],
    groupByFn: groupByFnDefault,
  },
  defaultSortKey: 'afDealId',
  defaultSortOrder: SortOrders.DESC,
})

export const TreatmentApprovalsOrRejectionsDealsModelByCountry = compose<
  typeof ApprovalsModel
>(
  whiteListColumn([
    'countryOrganisationGrantingDecision',
    'parentChild',
    // START OF CHILD
    'lookupVaccineName',
    'organisationsRegulators',
    'approvalType',
    'indication',
    'patientSetting',
    'pressReleases',
    'media',
    'supportingDocumentsUrlApprovalsFda',
    'clinicalTrials',
    'afDecisionDate',
    // END OF CHILD
  ]),
  renameColumn('lookupVaccineName', 'Treatment Name'),
  renameColumn('organisationsRegulators', 'Regulatory body')
)({
  ...ApprovalsModel,
  name: 'By Country',
  grouping: {
    groupBy: 'countryOrganisationGrantingDecision',
    parentColumns: ['countryOrganisationGrantingDecision'],
    groupByFn: groupByFnDefault,
  },
  defaultSortKey: 'countryOrganisationGrantingDecision',
  defaultSortOrder: SortOrders.ASC,
})

export const TreatmentMediaModel = compose<typeof MediaModel>(
  addOrOverrideColumn(
    {
      key: 'vaccine',
      type: ValueType.MULTI,
      label: 'Candidate',
      width: 150,
      graphType: 'Pie',
      customDataFilter: (data) => customFilterNaNGraphData(data, 'vaccine'),
    },
    5
  ),
  whiteListColumn([
    'title',
    'source',
    'afTopic',
    'datePublished',
    'link',
    'vaccine',
    'name',
    'description',
    'companyResearchInstitution',
    'afTags',
  ])
)(MediaModel)

export const TreatmentTweetsModel = whiteListColumn<typeof TweetsModel>([
  'createdAt',
  'screenName',
  'lookupSpecialitiesFromTwitterUsers',
  'fullText',
  'lookupAfUniqueTreatmentName',
  'afTweetType',
  'keywordsClean',
])({ ...TweetsModel, displayKey: 'screenName' })

export const TreatmentsCountriesModel = whiteListColumn<typeof CountriesModel>([
  'name',
  'uiCandidateCount',
  'uiClinicalTrialsCount',
  'uiTrialToCandidateRatio',
  'luInfectionRatePer10000',
  'afSupplyDealVaccine',
  'afWillingnessToTakeA_COVID19Vaccine',
  'luEmergencyApproval',
  'afTotalVaccinations',
  'afTotalDeliveries',
])(CountriesModel)
