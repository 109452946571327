import GenericTable from 'modules/Tables'
import BarChart from 'modules/Tables/Dashboard/AllDashboards/BarChart'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
  defaultDashboardConfig,
} from 'interfaces/navigationPage.interface'

import { blackListColumn } from 'utils/overrideModel'

import { IDA360Slugs } from './IDA360Menu'
import {
  IDA360CandidatesModel,
  IDA360ApprovalsModel,
  IDA360TrialsModel,
  IDA360CandidatesAllModel,
  IDA360CandidatesTreatmentsModel,
  IDA360DealsModel,
  IDA360DealsPriceOrientedModel,
  IDA360PremiumRevenueModel,
  NewsPressReleasesModel,
  NewsPressReleasesVaccineTreatmentModel,
  IDA360CandidatesAMRModel,
  IDA360DealsSupplyOrientedModel,
} from './IDA360Models'
import messages from './IDA360WarningMessages'

type IDA360Page<TModel extends IModel<any>> = ISingleNavigationPage<
  IDA360Slugs,
  TModel
>

const Candidates: IDA360Page<typeof IDA360CandidatesModel> = {
  key: 'Candidates',
  path: ['candidates'],
  model: {
    ...IDA360CandidatesModel,
    warningMessage: messages.candidates,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'id_all',
      airtableBase: 'lzdb',
      overrideModel: {
        ...IDA360CandidatesAllModel,
        warningMessage: messages.candidates,
      },
    },
    {
      name: 'Vaccines',
      airtableName: 'id_vaccines',
      airtableBase: 'lzdb',
    },
    {
      name: 'Treatments',
      airtableName: 'id_treatments',
      airtableBase: 'lzdb',
      overrideModel: {
        ...IDA360CandidatesTreatmentsModel,
        warningMessage: messages.candidates,
      },
    },
    {
      name: 'Antimicrobial Resistance',
      airtableName: 'id_amr',
      airtableBase: 'lzdb',
      overrideModel: {
        ...IDA360CandidatesAMRModel,
        warningMessage: messages.candidatesAmr,
      },
    },
  ],
  autoGenerateDashboard: {
    ...defaultDashboardConfig,
    render: BarChart,
    verticalAxisLabel: 'Number of Candidates',
    defaultHorizontalFilterValue: {
      label: 'Phase',
      value: 'latestPhaseManual',
    },
    defaultStackByFilterValue: { label: 'Diseases', value: 'diseaseName' },
    filterBlacklistOptions: [
      'alternativeNames',
      'vaccineValency',
      'frontendNotes',
    ],
    sortingOrders: {
      latestPhaseManual: [
        'Preclinical',
        'Phase 0',
        'Phase I',
        'Phase I/II',
        'Phase II',
        'Phase II/III',
        'Phase III',
        'Phase IV',
        'Approved',
        'Discontinued',
        'Paused',
        'Inactive',
        'Not Applicable',
      ],
      designationA: ['Vaccine', 'Treatment'],
      designationB: [
        'Pre-exposure Prophylactic',
        'Post-exposure Prophylactic',
        'Therapeutic',
        'Unknown',
      ],
    },
  },
}

const Trials: IDA360Page<typeof IDA360TrialsModel> = {
  key: 'Trials',
  path: ['trials', 'table'],
  model: {
    ...IDA360TrialsModel,
    warningMessage: messages.clinicalTrials,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'id_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccines',
      airtableName: 'id_vaccines',
      airtableBase: 'lzdb',
      overrideModel: blackListColumn(['designationA']),
    },
    {
      name: 'Treatments',
      airtableName: 'id_treatments',
      airtableBase: 'lzdb',
      overrideModel: blackListColumn(['designationA']),
    },
  ],
}

const Approvals: IDA360Page<typeof IDA360ApprovalsModel> = {
  key: 'Approvals',
  path: ['approvals', 'table'],
  model: {
    ...IDA360ApprovalsModel,
    warningMessage: messages.approvals,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'id_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccines',
      airtableName: 'id_vaccines',
      airtableBase: 'lzdb',
    },
    {
      name: 'Treatments',
      airtableName: 'id_treatments',
      airtableBase: 'lzdb',
    },
  ],
}
const SupplyPrice: IDA360Page<typeof IDA360DealsModel> = {
  key: 'SupplyPrice',
  path: ['supply-price', 'table'],
  model: {
    ...IDA360DealsModel,
    warningMessage: messages.supplyAndPrice,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'id_deals',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccine Supply',
      airtableName: 'id_deal_supply',
      airtableBase: 'lzdb',
      overrideModel: {
        ...IDA360DealsSupplyOrientedModel,
      },
    },
    {
      name: 'Vaccine Prices',
      airtableName: 'id_deal_price',
      airtableBase: 'lzdb',
      overrideModel: {
        ...IDA360DealsPriceOrientedModel,
        warningMessage: messages.supplyAndPrice,
      },
    },
  ],
}
const Revenue: IDA360Page<typeof IDA360PremiumRevenueModel> = {
  key: 'SupplyPrice',
  path: ['revenue', 'table'],
  model: {
    ...IDA360PremiumRevenueModel,
    warningMessage: messages.revenue,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'id_all',
      airtableBase: 'lzdb',
    },
  ],
}
const NewsPressReleases: IDA360Page<typeof NewsPressReleasesModel> = {
  key: 'NewsPressReleases',
  path: ['news-press-releases'],
  model: {
    ...NewsPressReleasesModel,
    warningMessage: messages.newsAndPressReleases,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'ida_all',
      airtableBase: 'lzdb',
    },
    {
      name: 'Vaccines',
      airtableName: 'ida_vaccines',
      airtableBase: 'lzdb',
      overrideModel: {
        ...NewsPressReleasesVaccineTreatmentModel,
        warningMessage: messages.newsAndPressReleases,
      },
    },
    {
      name: 'Treatments',
      airtableName: 'ida_treatments',
      airtableBase: 'lzdb',
      overrideModel: {
        ...NewsPressReleasesVaccineTreatmentModel,
        warningMessage: messages.newsAndPressReleases,
      },
    },
  ],
}

export const IDA360Pages: INavigationPages<IDA360Slugs> = {
  Candidates,
  Trials,
  Approvals,
  SupplyPrice,
  Revenue,
  NewsPressReleases,
}
