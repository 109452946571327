import { useAxiosRequestConfig } from 'api/useAxios'
import axios from 'axios'
import { useState } from 'react'

import { assistantsId, ChatHistoryT, ChatT } from 'routes/common/ai-search'

async function postMessage(
  message: ChatT,
  threadId: string,
  _assistantId: string,
  baseURL: string,
  authHeader: any,
  cb: (value: any) => void,
  streamCB: (value: any) => void
) {
  const res = await fetch(`${baseURL}/llm-thread/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
    body: JSON.stringify({
      messages: message.content,
      threadId,
      _assistantId,
    }),
    mode: 'cors',
  })

  if (!res.body) return
  const reader = res.body.getReader()
  const decoder = new TextDecoder()
  const loopRunner = true
  let content = ''
  let sources = []

  while (loopRunner) {
    const { value, done } = await reader.read()
    if (done) {
      break
    }
    const decodedChunk = decoder.decode(value, { stream: true })
    if (decodedChunk.includes('file_citation')) {
      sources = JSON.parse(decodedChunk)
    } else {
      content += decodedChunk
      streamCB(decodedChunk)
    }
  }
  cb({
    id: 1,
    role: 'assistant',
    content: content,
    sources: sources,
  })
}
async function getThread(baseURL: string, authHeader: any) {
  const res = await axios.get(`${baseURL}/llm-thread/`, {
    headers: { ...authHeader },
  })

  if (!res.data) return
  return res.data.thread_id
}
async function askTsQuestion(
  baseURL: string,
  authHeader: any,
  message: ChatT,
  cb: (value: any) => void
) {
  const res = await axios.post(
    `${baseURL}/ask-ts-question/`,
    {
      question: message.content,
    },
    { headers: { ...authHeader } }
  )
  if (!res.data) return
  const response = res.data[0][0][1][1][1]
  cb({
    id: 1,
    role: 'assistant',
    content: response,
    sources: [],
  })
  return res.data
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export default function useAskAI() {
  const [threadId, setThread] = useState<string | null>()
  const { baseURL, ...authHeader } = useAxiosRequestConfig()

  const ask = async (
    messages: ChatHistoryT,
    assistantId: string,
    cb: (value: any) => void,
    streamCB: (value: any) => void
  ) => {
    let currentThreadId = threadId

    if (assistantId === assistantsId.ts_infections_biorisk) {
      const response = await askTsQuestion(
        baseURL,
        authHeader.headers,
        messages[messages.length - 1],
        cb
      )
      return response
    }
    if (assistantId === assistantsId.router) {
      await timeout(2000)
      return cb({
        id: 1,
        role: 'assistant',
        content: 'text',
        sources: [],
      })
    }

    if (!currentThreadId) {
      currentThreadId = await getThread(baseURL, authHeader.headers)
      setThread(currentThreadId)
    }

    if (currentThreadId) {
      // TODO: This is to fake the router, should be removed once the tables are working correctly.

      const response = await postMessage(
        messages[messages.length - 1],
        currentThreadId,
        assistantId,
        baseURL,
        authHeader.headers,
        cb,
        streamCB
      )
      return response
    }
  }
  const clearThread = () => {
    setThread(null)
  }
  return { ask, clearThread }
}
