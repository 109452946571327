import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { RespiratoryCandidatesModel } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'

import { addOrOverrideColumn, compose } from 'utils/overrideModel'

import { InfluenzaSlugs } from './InfluenzaMenu'
import {
  InfluenzaApprovalsModel,
  InfluenzaCandidatePapersModel,
  InfluenzaCandidatesCombinationVaccinesModel,
  InfluenzaCandidatesNonVaccinesModels,
  InfluenzaCandidatesVaccineModel,
  InfluenzaMediaModel,
  InfluenzaPressReleasesModel,
  InfluenzaTrialsModel,
  NonVaccinesResultsModel,
  VaccinesResultsModel,
} from './InfluenzaModels'
import { warningMessages } from './InfluenzaWarningMessages'

type InfluenzaNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<InfluenzaSlugs, TModel>

const InfluenzaCandidatesVaccine: InfluenzaNavigationPage<
  typeof InfluenzaCandidatesVaccineModel
> = {
  key: 'InfluenzaCandidatesVaccine',
  path: ['candidates', 'table'],
  model: {
    ...InfluenzaCandidatesVaccineModel,
    newNote: {
      text: warningMessages.candidatesNewNote,
      navigateTo: '/influenza-commercial/revenue/table',
    },
  },
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vax',
      airtableBase: 'respiratory360',
      overrideModel: (model) =>
        compose(
          addOrOverrideColumn(
            RespiratoryCandidatesModel.schema.columns.find(
              (x) => x.key === 'influenzaValency'
            )!,
            4
          )
        )(model),
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesNonVaccinesModels,
      }),
    },
    {
      name: 'Combination vaccines',
      airtableName: 'api_combination_vaccines_influenza',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesCombinationVaccinesModel,
      }),
    },
    {
      name: 'Seasonal Vaccines',
      airtableName: 'front_end_export_influenza_vax_seasonal',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesVaccineModel,
      }),
    },
    {
      name: 'Pandemic Vaccines',
      airtableName: 'front_end_export_influenza_vax_pandemic',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesVaccineModel,
      }),
    },
  ],
}

const InfluenzaCandidatePapers: InfluenzaNavigationPage<
  typeof InfluenzaCandidatePapersModel
> = {
  key: 'InfluenzaPublications',
  path: ['science', 'publications'],
  model: InfluenzaCandidatePapersModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaTrials: InfluenzaNavigationPage<typeof InfluenzaTrialsModel> = {
  key: 'InfluenzaTrials',
  path: ['trials', 'all-data'],
  model: InfluenzaTrialsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const InfluenzaNewsPressReleases: InfluenzaNavigationPage<
  typeof InfluenzaPressReleasesModel
> = {
  key: 'InfluenzaNewsPressReleases',
  path: null,
  model: InfluenzaPressReleasesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const InfluenzaNewsMedia: InfluenzaNavigationPage<typeof InfluenzaMediaModel> =
  {
    key: 'InfluenzaNewsMedia',
    path: null,
    model: InfluenzaMediaModel,
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'front_end_export_influenza_default_view',
        airtableBase: 'respiratory360',
      },
      {
        name: 'Vaccines',
        airtableName: 'front_end_export_influenza_vaccines_view',
        airtableBase: 'respiratory360',
      },
      {
        name: 'Non-Vaccines',
        airtableName: 'front_end_export_influenza_treatments_view',
        airtableBase: 'respiratory360',
      },
    ],
  }

const InfluenzaVaccineResults: InfluenzaNavigationPage<
  typeof VaccinesResultsModel
> = {
  key: 'InfluenzaVaccineResults',
  path: ['results', 'vaccines'],
  model: VaccinesResultsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'Vaccines',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaNonVaccineResults: InfluenzaNavigationPage<
  typeof NonVaccinesResultsModel
> = {
  key: 'InfluenzaNonVaccineResults',
  path: ['results', 'non-vaccines'],
  model: NonVaccinesResultsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'Non-vaccines',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaApprovals: InfluenzaNavigationPage<
  typeof InfluenzaApprovalsModel
> = {
  key: 'InfluenzaApprovals',
  path: ['approvals', 'table'],
  model: InfluenzaApprovalsModel,
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'Front_end_influenza_vaccine_approvals',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'Front_end_influenza_treatment_approvals',
      airtableBase: 'respiratory360',
    },
  ],
}

export const InfluenzaPages: INavigationPages<InfluenzaSlugs> = {
  InfluenzaCandidatesVaccine,
  InfluenzaCandidatePapers,
  InfluenzaTrials,
  InfluenzaNewsPressReleases,
  InfluenzaNewsMedia,
  InfluenzaVaccineResults,
  InfluenzaNonVaccineResults,
  InfluenzaApprovals,
}
