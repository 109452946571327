import { WidgetProps } from 'routes/dashboard/WidgetComponents'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

export type IDTreatments360NewsPressData =
  | 'id'
  | 'title'
  | 'diseases'
  | 'candidates'
  | 'designationA'
  | 'designationB'
  | 'type'
  | 'subType'
  | 'organisations'
  | 'publishedAt'
  | 'descriptionClean'
  | 'feedCategory'
  | 'indicationIds'
  | 'feedDescription'
  | 'pathogenTypes'
  | 'description'
  | 'titleDisplay'

const allowedAirtables = ['ida_vaccines', 'ida_treatments', 'ida_all'] as const

export const getNewsPressReleasesDefaultWidget = ({
  view,
  model,
  title,
}: WidgetProps) => ({
  heading: title ?? 'News & Press Releases',
  body: (
    <WidgetComponentArticle<IDTreatments360NewsPressData>
      view={view}
      model={model ?? IDTreatment360NewsAndPressReleasesModel}
      url='title'
      title='title'
      date='publishedAt'
    />
  ),
})

export const IDTreatment360NewsAndPressReleasesModel: IModel<
  Record<IDTreatments360NewsPressData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'News & Press Releases',
  entityName: 'Revenue',
  searchField: 'title',
  endpoint: 'rss-article',
  defaultSortKey: 'publishedAt',
  defaultSortOrder: SortOrders.DESC,
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'title',
        label: 'Title',
        type: ValueType.URL,
        width: 400,
      },
      {
        key: 'indicationIds',
        label: 'Indications Id',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'diseases',
        label: 'Diseases',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'candidates',
        label: 'Candidate Name',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'designationB',
        label: 'Candidate Category',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'type',
        label: 'Candidate Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'subType',
        label: 'Candidate Sub-Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'organisations',
        label: 'Organisation',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'publishedAt',
        label: 'Published At',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'descriptionClean',
        label: 'Description',
        type: ValueType.LONG,
        width: 300,
      },
      {
        key: 'feedCategory',
        label: 'Category',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'feedDescription',
        label: 'Feed Source',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'description',
        label: 'Description',
        type: ValueType.TEXT,
        width: 300,
      },
      {
        key: 'titleDisplay',
        label: 'Title',
        type: ValueType.TEXT,
        width: 400,
      },
    ],
  },
}
