import { Flex, Heading } from '@chakra-ui/react'

import { useTablePageData } from 'modules/Tables/TablePageProvider'

import { AdvancedFilterUpdaterProp } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import useIsMobile from 'utils/useIsMobile'

import NewNotePopover from './NewNotePopover'
import QuickFilter from './QuickFilter'
import WarningPopover from './WarningPopover'

type PropTypes = {
  hideQuickFilter?: boolean
  title: string
  isLoading?: boolean
  applyFilter?: (e: AdvancedFilterUpdaterProp) => void
}

const PageHeader = ({
  title,
  isLoading,
  applyFilter,
  hideQuickFilter,
}: PropTypes) => {
  const [isMobile] = useIsMobile()
  const { model } = useTablePageData()
  return (
    <Flex
      flexDir={'column'}
      border={!!model.quickFilter && !hideQuickFilter ? '1px solid' : 'none'}
      borderColor={'gray3'}
      w={isMobile ? '90%' : '50%'}
      borderRadius={'8px'}
      mb={!!model.quickFilter && !hideQuickFilter ? '24px' : 0}
    >
      <Flex
        padding={!!model.quickFilter && !hideQuickFilter ? '8px 24px' : '0'}
        h='42px'
        alignItems='center'
        pb={4}
      >
        {!model.hideTableHeader && (
          <Heading
            fontSize='24px'
            color='primary'
            fontWeight='semibold'
            letterSpacing='-0.1px'
          >
            {title}
          </Heading>
        )}
        <WarningPopover message={model.warningMessage} />
        {model.newNote ? (
          <NewNotePopover
            navigate={model.newNote.navigateTo}
            message={model.newNote.text}
          />
        ) : null}
      </Flex>
      {!!model.quickFilter && !hideQuickFilter && (
        <Flex bg='gray5' borderRadius={'8px'} padding={'20px 24px'} h='30px'>
          {!isLoading && applyFilter && (
            <QuickFilter quickFilter={applyFilter} />
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default PageHeader
