import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  InfluenzaMediaModel,
  InfluenzaPressReleasesModel,
  InfluenzaCandidatePapersModel,
  InfluenzaTrialsModel,
} from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaModels'

import { RSVSlugs } from './RSVMenu'
import {
  RsvApprovalsNonVaccinesModel,
  RsvApprovalsVaccinesModel,
  RSVCandidatesCombinationVaccinesModel,
  RSVCandidatesTreatmentModel,
  RSVCandidatesVaccineModel,
  RSVToolMetaNonVaccineEfficacyModel,
  RSVToolMetaVaccineEfficacyModel,
} from './RSVModels'

type RSVNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  RSVSlugs,
  TModel
>

const RSVCandidatesVaccine: RSVNavigationPage<
  typeof RSVCandidatesVaccineModel
> = {
  key: 'RSVCandidatesVaccine',
  path: ['candidates', 'table'],
  model: RSVCandidatesVaccineModel,
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vax',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments',
      airtableBase: 'respiratory360',
      overrideModel: RSVCandidatesTreatmentModel,
    },
    {
      name: 'Combination vaccines',
      airtableName: 'combination vaccines RSV',
      airtableBase: 'respiratory360',
      overrideModel: RSVCandidatesCombinationVaccinesModel,
    },
  ],
}

const RSVCandidatePapers: RSVNavigationPage<
  typeof InfluenzaCandidatePapersModel
> = {
  key: 'RSVCandidatePapers',
  path: ['science', 'publications'],
  model: InfluenzaCandidatePapersModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVResultsVaccines: RSVNavigationPage<
  typeof RSVToolMetaVaccineEfficacyModel
> = {
  key: 'RSVResultsVaccines',
  path: ['results', 'vaccines'],
  model: RSVToolMetaVaccineEfficacyModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'results_rsv_vaccine_trials_frontend',
      airtableBase: 'respiratory360',
    },
  ],
}
const RSVResultsNonVaccines: RSVNavigationPage<
  typeof RSVToolMetaVaccineEfficacyModel
> = {
  key: 'RSVResultsNonVaccines',
  path: ['results', 'non-vaccines'],
  model: RSVToolMetaNonVaccineEfficacyModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'results_rsv_treatments_frontend',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVTrials: RSVNavigationPage<typeof InfluenzaTrialsModel> = {
  key: 'RSVTrials',
  path: ['trials', 'all-data'],
  model: InfluenzaTrialsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const RSVNewsPressReleases: RSVNavigationPage<
  typeof InfluenzaPressReleasesModel
> = {
  key: 'RSVNewsPressReleases',
  path: null,
  model: InfluenzaPressReleasesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const RSVNewsMedia: RSVNavigationPage<typeof InfluenzaMediaModel> = {
  key: 'RSVNewsMedia',
  path: null,
  model: InfluenzaMediaModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_rsv_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_rsv_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_rsv_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const ApprovalsVaccines: RSVNavigationPage<typeof RsvApprovalsVaccinesModel> = {
  key: 'Vaccines',
  path: ['approvals', 'table'],
  model: RsvApprovalsVaccinesModel,
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'api_RSV_Vaccines',
      airtableBase: 'respiratory360',
    },
    {
      name: 'NonVaccines',
      airtableName: 'api_RSV_Treatments',
      airtableBase: 'respiratory360',
      overrideModel: RsvApprovalsNonVaccinesModel,
    },
  ],
}

export const RSVPages: INavigationPages<RSVSlugs> = {
  RSVCandidatesVaccine,
  RSVCandidatePapers,
  RSVResultsVaccines,
  RSVResultsNonVaccines,
  RSVTrials,
  RSVNewsPressReleases,
  RSVNewsMedia,
  ApprovalsVaccines,
}
