import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type BioriskSlugs =
  | ''
  | 'ai'
  | 'risk-forecasts'
  | 'reports'
  | 'media'
  | 'various'
  | 'vaccinations'
  | 'policy'
  | 'us-states'
  | 'supply'
  | 'policies'
  | 'vaccine-strategy'
  | 'outbreak-overview'
  | 'disease-information'
  | 'press-releases'
  | 'news'
  | 'papers'
  | 'outbreak-progression'
  | 'alerts'
  | 'research-briefs'
  | 'global-disease-resurgence'
  | 'daily-briefing'
  | 'avian-influenza'

const BioriskMenu: Array<INavigationMenu<BioriskSlugs>[]> = [
  [
    {
      title: 'Airfinity Intelligence (Beta)',
      slug: 'ai',
      createdAt: new Date(2025, 2, 21),
    },
    {
      title: 'Dashboard',
      slug: '',
      description:
        'Find all the latest country-level forecasts on this interactive dashboard. Forecasts on vaccinations, supply, infections; the supplier view includes vaccine production forecasts. The people protected tool shows country-level protection level based on previous infections and vaccine efficacies.',
      createdAt: new Date(2021, 10, 29),
    },
    {
      title: 'Alerts',
      slug: 'alerts',
      description: '',
      createdAt: new Date(2024, 5, 25),
    },
    {
      title: 'Daily Briefing',
      slug: 'daily-briefing',
      description: '',
      createdAt: new Date(2024, 5, 25),
    },
    {
      title: 'Reports',
      slug: 'reports',
      description:
        'All the weekly reports and country-level deep dive reports.',
      createdAt: new Date(2021, 3, 1),
      children: [
        { title: 'Reports', slug: 'reports', createdAt: new Date(2022, 3, 13) },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
    {
      title: 'Avian Influenza',
      slug: 'avian-influenza',
      createdAt: new Date(2024, 6, 5),
    },
  ],
  [
    {
      title: 'Outbreak Overview',
      slug: 'outbreak-overview',
      description:
        'Outbreaks of the top 10 diseases by cases over the past 4 weeks.',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'Outbreak Progression',
      slug: 'outbreak-progression',
      description:
        'Outbreaks of the top 10 diseases by cases over the past 4 weeks.',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'News',
      slug: 'news',
      description: '',
      createdAt: new Date(2022, 12, 29),
      children: [
        {
          title: 'Press Releases',
          slug: 'press-releases',
          description: '',
          createdAt: new Date(2022, 12, 28),
        },
        {
          title: 'Media',
          slug: 'media',
          description: '',
          createdAt: new Date(2022, 12, 29),
        },
      ],
    },
    {
      title: 'Disease Information',
      slug: 'disease-information',
      description: '',
      createdAt: new Date(2021, 3, 1),
    },
    {
      title: 'Global Disease Resurgence',
      slug: 'global-disease-resurgence',
      createdAt: new Date(2024, 5, 11),
    },
  ],
]

export { BioriskMenu }
