import React, { useContext } from 'react'
import { useAuth, hasAuthParams } from 'react-oidc-context'

import { AuthContext } from 'contexts'

import { LoadingAnimation } from 'components'

export type AirfinitySecureRoutePropTypes = {
  children: React.ReactNode
}

const setPreviousPath = () => {
  const prevPath =
    window.location.pathname + window.location.search + window.location.hash

  window.sessionStorage.setItem('prevPath', prevPath)
}

/**
 * A SecureRoute. Wrap anything you want to ensure the user has to be logged in with this.
 * We also wait for userInfo to be loaded and render a spinner while waiting
 */
const AirfinitySecureRoute = ({ children }: AirfinitySecureRoutePropTypes) => {
  const auth = useAuth()
  const { userInfo } = useContext(AuthContext)

  // If auth is loading or the user is not authenticated yet
  if (auth.isLoading || (auth.isAuthenticated && !userInfo)) {
    return <LoadingAnimation />
  }

  if (
    hasAuthParams() ||
    (!auth.isLoading && !auth.isAuthenticated && !auth.activeNavigator)
  ) {
    void (async (): Promise<void> => {
      await auth.signinRedirect()
    })()
  }

  function OnRedirecting() {
    setPreviousPath()
    return <LoadingAnimation />
  }

  if (!auth.isAuthenticated) return OnRedirecting()

  return <>{children}</>
}

export default AirfinitySecureRoute
