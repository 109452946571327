import { useLocation } from 'react-router-dom'

import { useAppRoute } from 'routes/utils'

import * as trackingFunctions from '.'

type TrackingTypes = typeof trackingFunctions

const useTracking = (): [TrackingTypes] => {
  const app = useAppRoute()
  const { pathname } = useLocation()
  const [, , page, subPage] = pathname.split('/')

  const trackingFuncEntries = Object.entries(trackingFunctions).map(
    ([key, trackingFunc]): [string, any] => {
      return [
        key,
        (extraProps: any) =>
          trackingFunc({ app, page, subPage, ...extraProps }),
      ]
    }
  )
  return [Object.fromEntries(trackingFuncEntries) as TrackingTypes]
}

export default useTracking
