import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type VaccinesSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'science'
  | 'reports'
  | 'standard'
  | 'combination-vaccines'
  | 'research-briefs'
  | 'trials'
  | 'recent-trials-map'
  | 'results'
  | 'safety'
  | 'all'
  | 'booster-campaigns'
  | 'approvals'
  | 'this-week'
  | 'vaccine-performance'
  | 'progress-tracker'
  | 'landscape'
  | 'vaccines-under-review'
  | 'total-per-country-vaccine'
  | 'duration-timeline'
  | 'publications'
  | 'comparative-immunogenicity'
  | 'updated-vaccine-effectiveness'
  | 'historical-results-data'
  | 'historical-vaccine-performance'

const VaccinesMenu: Array<INavigationMenu<VaccinesSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'The latest news articles, publications, tweets and weekly reports for COVID-19 vaccines.',
      createdAt: new Date(2021, 3, 1),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description: 'See information on all COVID-19 vaccine candidates.',
      createdAt: new Date(2020, 7, 13),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 0, 1),
        },
        {
          title: 'Pipeline',
          slug: 'pipeline',
          createdAt: new Date(2020, 4, 11),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 9),
        },
      ],
    },
    {
      title: 'Combination Vaccines',
      slug: 'combination-vaccines',
      createdAt: new Date(2024, 5, 28),
    },
    {
      title: 'New Science',
      slug: 'science',
      description:
        'All scientific papers related to vaccine candidates split into relevant sections.',
      createdAt: new Date(2020, 7, 13),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2020, 7, 13),
        },
      ],
    },
    {
      title: 'Booster Campaigns',
      slug: 'booster-campaigns',
      description: 'In depth insights into various topics related to COVID-19.',
      createdAt: new Date(2024, 6, 14),
    },
    {
      title: 'Trials',
      slug: 'trials',
      description: 'List of clinical trials involving COVID-19 vaccines.',
      createdAt: new Date(2020, 9, 8),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2020, 9, 21),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
        {
          title: 'Landscape',
          slug: 'landscape',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      description: 'See clinical and real-world data on COVID-19 vaccines.',
      createdAt: new Date(2021, 2, 3),
      children: [
        {
          title: 'Comparative Immunogenicity',
          slug: 'comparative-immunogenicity',
          createdAt: new Date(2024, 2, 15),
        },
        {
          title: 'Updated Vaccine Effectiveness',
          slug: 'updated-vaccine-effectiveness',
          createdAt: new Date(2024, 2, 15),
        },
        {
          title: 'Historical Results Data',
          slug: 'historical-results-data',
          createdAt: new Date(2023, 2, 15),
        },
        {
          title: 'Historical Vaccine Performance',
          slug: 'historical-vaccine-performance',
          createdAt: new Date(2024, 3, 2),
        },
      ],
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      createdAt: new Date(2022, 2, 11),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'Total per Country & Vaccine',
          slug: 'total-per-country-vaccine',
          createdAt: new Date(2022, 2, 11),
        },
        {
          title: 'This Week',
          slug: 'this-week',
          createdAt: new Date(2022, 4, 3),
        },
        {
          title: 'Vaccines Under Review',
          slug: 'vaccines-under-review',
          createdAt: new Date(2022, 12, 7),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      createdAt: new Date(2022, 7, 24),
      children: [
        {
          title: 'Reports',
          slug: 'standard',
          createdAt: new Date(2022, 7, 24),
        },

        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { VaccinesMenu }
