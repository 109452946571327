import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'
import EmbedNote from 'components/Embed/Note'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { CustomReportPage } from './EpiCombinationsReport'
import { EpidemiologySlugs } from './EpidemiologyMenu'
import { EpidemiologyPages, LongCovidPages } from './EpidemiologyPages'

const Reports: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['reports', 'standard'],
  component: () => <FullReportPage showCategory={false} />,
}

const ResearchBriefs: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const LongCovidDashboard: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['long-covid'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Risk Factors',
          body: <TableauEmbed path='/Long-COVID_RF/Dashboard1' />,
        },
        LongCovidPages['LongCovidPresentations'],
      ]}
    />
  ),
}

const VariantsLatestNews: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['variants', 'latest-news'],
  component: () => (
    <Dashboard
      hideReports
      widgetTabs={[
        getMediaDefaultWidget({
          title: 'Media',
          view: EpidemiologyPages['VariantsMedia'].views[0],
        }),
        getPressReleasesDefaultWidget({
          view: EpidemiologyPages['VariantsPressReleases'].views[0],
        }),
      ]}
    />
  ),
}

const VariantsVirologyImpact: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['variants', 'impact'],
  component: () => (
    <TableauEmbed path='/Variantvaccineheatmap_new/Dashboard1' />
  ),
}

const VariantPrevalance: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['variants', 'variant-prevalence'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Variants Dashboard',
          body: (
            <TableauEmbed
              path='/Variantdashboard/Variantdashboardplatform'
              height='120vh'
            />
          ),
        },
        {
          heading: 'Read Latest Variant Analysis',
          body: <CustomReportPage id='1411' />,
        },
      ]}
    />
  ),
}

const CasesHospitalisationDeaths: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['overview', 'cases-hospitalisation-deaths'],
  component: () => (
    <TableauEmbed path='/COVID-19Epidemiology_17056802791940/CovidEpidemiology' />
  ),
}

const EpidOverviewCovid19PandemicOverview: ISingleCustomPage<EpidemiologySlugs> =
  {
    path: ['overview', 'covid-pandemic-overview'],
    component: () => (
      <TableauEmbed path='/OWID_HS_ML_2/Historicdatadashboard_1' />
    ),
  }

const RespiratoryDiseaseCases: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['overview', 'respiratory-disease-cases'],
  component: () => (
    <TableauEmbed path='/ILISARIdashboards1Feb/ILIcasedashboardV1' />
  ),
}

const VaccinationsCovid19PandemicOverview: ISingleCustomPage<EpidemiologySlugs> =
  {
    path: ['vaccinations', 'covid-pandemic-overview'],
    component: () => (
      <TableauEmbed path='/OWID_HS_ML_2/Historicvaxdatadashboard' />
    ),
  }

const WastewaterDashboard: ISingleCustomPage<EpidemiologySlugs> = {
  path: ['overview', 'wastewater'],
  component: (e) => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Wastewater dashboard',
          body: (
            <>
              <EmbedNote
                heading='New data added'
                text={`Data for Germany has been added to the National average wastewater
        values – hover over the country name to see the specific methodology.
        Data has also been added for Germany and Japan to the National
        site-level wastewater values.`}
              />
              <TableauEmbed
                path='/wastewater_17091305231250/Dashboard1'
                height='120vh'
              />
            </>
          ),
        },
        {
          heading: 'Read Latest Wastewater Analysis',
          body: <CustomReportPage id='1410' />,
        },
      ]}
    />
  ),
}

export const EpidemiologyCustomPages: Record<
  string,
  ISingleCustomPage<EpidemiologySlugs>
> = {
  CasesHospitalisationDeaths,
  EpidOverviewCovid19PandemicOverview,
  RespiratoryDiseaseCases,
  VaccinationsCovid19PandemicOverview,
  VariantPrevalance,
  VariantsLatestNews,
  VariantsVirologyImpact,
  Reports,
  LongCovidDashboard,
  ResearchBriefs,
  WastewaterDashboard,
}
