import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useContext, useState } from 'react'

import { AuthContext } from 'contexts'

import { LoadingAnimation } from 'components'

import { useAllBriefs } from 'api/cms/briefs/useBriefs'

import useTracking from 'tracking/useTracking'

import { AccessGroups } from 'enums/AccessGroups'

import { appsListUnion } from 'config/apps'

import BriefCard from './BriefCard'
import Filter from './Filters'
import { filterBriefs } from './Helpers'

export const ResearchBriefsAppsWhitelist = [
  // -------- Covid 19
  AccessGroups.Vaccines,
  AccessGroups.Treatments,
  AccessGroups.CovidCommercialTherapeutics,
  AccessGroups.CovidCommercialVaccines,
  AccessGroups.Epidemiology,

  // -------- Influenza
  AccessGroups.Influenza,
  AccessGroups.InfluenzaCommercial,
  AccessGroups.InfluenzaEpidemiology,

  // -------- RSV
  AccessGroups.RSV,
  AccessGroups.RSVEpidemiology,
  AccessGroups.RSVCommercial,

  // -------- IDA
  AccessGroups.IDA360Vaccines,
  AccessGroups.IDA360Treatments,
  AccessGroups.IDA360ChikV,
  AccessGroups.IDA360Amr,
  AccessGroups.IDA360Malaria,
  AccessGroups.IDA360Dengue,

  // -------- Biorisk
  AccessGroups.Pandemic,
]

export const appsMapping = {
  'IDA 360': { slug: 'ida-360', name: 'IDA 360', parent: 'ida-360' },
  'COVID-19 Vaccines': {
    slug: 'covid-19-vaccines',
    name: 'COVID-19 Vaccines',
    parent: 'covid-19',
  },
  'COVID-19 Treatments': {
    slug: 'covid-19-therapeutics',
    name: 'COVID-19 Therapeutics',
    parent: 'covid-19',
  },
  'COVID-19 Epidemiology': {
    slug: 'epidemiology',
    name: 'COVID-19 Epidemiology',
    parent: 'covid-19',
  },
  'COVID-19 Vaccines Commercial Report': {
    slug: 'covid-19-commercial-vaccines',
    name: 'COVID-19 Vaccines Commercial',
    parent: 'covid-19',
  },
  'COVID-19 Therapeutics Commercial Report': {
    slug: 'covid-19-commercial-therapeutics',
    name: 'COVID-19 Therapeutics Commercial',
    parent: 'covid-19',
  },
  Influenza: { slug: 'influenza', name: 'Influenza', parent: 'influenza' },
  'Influenza Commercial': {
    slug: 'influenza-commercial',
    name: 'Influenza Commercial',
    parent: 'influenza',
  },
  RSV: { slug: 'rsv', name: 'RSV', parent: 'rsv' },
  'RSV Market & Production': {
    slug: 'rsv-commercial',
    name: 'RSV Commercial',
    parent: 'rsv',
  },
  Biorisk: { slug: 'biorisk', name: 'Biorisk', parent: 'biorisk' },
} as { [key: string]: { slug: appsListUnion; name: string; parent: string } }

const productsMapping = {
  'COVID-19': 'covid-19',
  Influenza: 'influenza',
  RSV: 'rsv',
  Biorisk: 'biorisk',
  IDA: 'ida-360',
} as { [key: string]: string }

const ResearchBrief = ({
  setSelectedApps,
}: {
  setSelectedApps: Dispatch<SetStateAction<string[] | undefined>>
}) => {
  const { appListUserCanAccess } = useContext(AuthContext)
  const [tracking] = useTracking()

  const { briefs, isFetched, isError } = useAllBriefs()

  const [selectedProducts, setProducts] = useState<string[] | undefined>([])
  const [selectedArea, setSelectedArea] = useState<string | undefined>()
  const [selectedContent, setSelectedContent] = useState<string[] | undefined>(
    []
  )

  function trackFilters(filterName: string, filterValue: string | string[]) {
    tracking.filterResearchBriefList({ filterName, filterValue })
  }

  function handleSelectedProduct(x: { value: string }[]) {
    const valueMap = x.map((x) => x.value)

    trackFilters('apps', valueMap)

    setProducts(valueMap)
    setSelectedApps(x.map((x) => productsMapping[x.value]))
  }
  function handleSelectArea({ value }: { value: string }) {
    trackFilters('area', value)

    if (value === 'All') setSelectedArea(undefined)
    else setSelectedArea(value)
  }
  function handleSelectContent(x: { value: string }[]) {
    const valueMap = x.map((x) => x.value)

    trackFilters('content', valueMap)

    setSelectedContent(valueMap)
  }

  const hasFilters =
    selectedProducts?.some((x) => !!x) ||
    !!selectedArea ||
    selectedContent?.some((x) => !!x)

  const filteredBriefs = filterBriefs(
    briefs,
    selectedProducts?.map((prod) => productsMapping[prod]),
    selectedArea,
    selectedContent
  )

  function createValues(arr: string[] | undefined) {
    return arr?.map((option) => ({
      label: option,
      value: option,
    }))
  }

  const hasBriefs = !!filteredBriefs && filteredBriefs.length > 0

  return (
    <Box
      w={{ lg: '70%', md: '100%' }}
      maxW={{ lg: '40vw', md: '100vw' }}
      pt={10}
    >
      <Text fontSize='3xl' fontWeight='bold' color='black' mb='6px'>
        Research Briefs
      </Text>
      <VStack mb='2rem' alignItems={'flex-start'}>
        <Text fontSize='sm'>Filters</Text>
        <HStack gap='1rem' w='100%'>
          <Filter
            onChange={handleSelectedProduct}
            options={['COVID-19', 'Influenza', 'RSV', 'Biorisk', 'IDA']}
            values={createValues(selectedProducts)}
            placeholder='Product'
            large
            zIndex={1}
          />
          <Filter
            onChange={handleSelectContent}
            options={['Science 360', 'Commercial', 'Epidemiology']}
            values={createValues(selectedContent)}
            placeholder='Content'
            large
            zIndex={1}
          />
          <Filter
            onChange={handleSelectArea}
            options={['All', 'Vaccines', 'Treatments']}
            values={
              selectedArea &&
              ({ label: selectedArea, value: selectedArea } as any)
            }
            placeholder='Area'
            isMulti={false}
            zIndex={1}
          />
        </HStack>
      </VStack>
      <Box maxH={'150vh'} overflowY='auto'>
        {hasBriefs ? (
          filteredBriefs?.map((brief, index) => {
            return (
              brief && (
                <BriefCard
                  brief={brief}
                  key={index}
                  appListUserCanAccess={appListUserCanAccess.map(
                    (app) => app.slug
                  )}
                />
              )
            )
          })
        ) : hasFilters || (isFetched && !isError) ? (
          <Flex
            justifyContent={'center'}
            flexDir='column'
            alignItems={'center'}
          >
            <Text>
              {hasFilters
                ? 'No Research Briefs matches filters'
                : 'There are no Research Briefs yet!'}
            </Text>
          </Flex>
        ) : isError ? (
          <Flex
            justifyContent={'center'}
            flexDir='column'
            alignItems={'center'}
          >
            <Text color={'red.500'}>Something went wrong, try again later</Text>
          </Flex>
        ) : (
          <Flex
            justifyContent={'center'}
            flexDir='column'
            alignItems={'center'}
          >
            <LoadingAnimation height='200px' />
            <Text>Loading Research Briefs</Text>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default ResearchBrief
